import http from "../api/http";
import serviceUtil from "../utils/service-util";

const referralService = {
  getReferrals: async (queryParams) => {
    const response = await http.backendInstance.get("/api/referral/list", { params: queryParams });

    return response.data;
  },
  getReferralById: async (referralId) => {
    const response = await http.backendInstance.get(`/api/referral/${referralId}`);

    return response.data;
  },
  createReferral: async (referral) => {
    const response = await http.backendInstance.post("/api/referral", referral);

    return response.data;
  },
  updateReferral: async (referral) => {
    const response = await http.backendInstance.put("/api/referral", referral);

    return response.data;
  },
  getLaboratories: async () => {
    const response = await http.backendInstance.get("/api/referral/laboratories");

    return response.data;
  },
  getLaboratoryById: async (laboratoryId) => {
    const response = await http.backendInstance.get(`/api/referral/laboratory/${laboratoryId}`);

    return response.data;
  },
  checkReferralCode: async (code) => {
    const response = await http.backendInstance.get(`/api/referral/check-referral-code/${code}`);

    return response.data;
  },
  publishReferral: async (referralId) => {
    const response = await http.backendInstance.put(`/api/referral/publish/${referralId}`);

    return response.data;
  },
  deleteReferral: async (referralId) => {
    const response = await http.backendInstance.put(`/api/referral/delete/${referralId}`);

    return response.data;
  },
  isResearchReferralTabAvailable: async (researchId) => {
    const response = await http.backendInstance.get(`/api/referral/referral-tab-available/${researchId}`);

    return response.data;
  },
  areAnyReferralTasksSubmitted: async (researchId) => {
    const response = await http.backendInstance.get(`/api/referral/any-referral-tasks-submitted/${researchId}`);

    return response.data;
  },
  createResearchReferral: async (researchReferral) => {
    const response = await http.backendInstance.post("/api/referral/research-referral", researchReferral);

    return response.data;
  },
  updateResearchReferral: async (researchReferral) => {
    const response = await http.backendInstance.put("/api/referral/research-referral", researchReferral);

    return response.data;
  },
  getResearchReferral: async (researchReferralId) => {
    const response = await http.backendInstance.get(`/api/referral/research-referral/${researchReferralId}`);

    return response.data;
  },
  publishResearchReferral: async (researchReferralId) => {
    const response = await http.backendInstance.put(`/api/referral/publish-research-referral/${researchReferralId}`);

    return response.data;
  },
  deleteResearchReferral: async (researchReferralId) => {
    const response = await http.backendInstance.put(`/api/referral/delete-research-referral/${researchReferralId}`);

    return response.data;
  },
  getResearchReferrals: async (researchId) => {
    const response = await http.backendInstance.get(`/api/referral/research-referrals/${researchId}`);

    return response.data;
  },
  getPublishedResearchReferrals: async (researchId) => {
    const response = await http.backendInstance.get(`/api/referral/published-research-referrals/${researchId}`);

    return response.data;
  },
  getResearchReferralParticipantTasks: async (researchId) => {
    const response = await http.backendInstance.get(`/api/referral/research-referral-participant-tasks/${researchId}`);

    return response.data;
  },
  canAddResearchReferralParticipants: async (formData) => {
    const response = await http.backendInstance.post(
      "/api/referral/can-add-research-referral-participants",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  },
  submitResearchReferralTasks: async (taskIds) => {
    const response = await http.backendInstance.put("/api/referral/submit-research-referral-tasks", { taskIds });

    return response.data;
  },
  getReserachReferralParticipants: async (queryParams) => {
    queryParams =
      serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);

    const response = await http.backendInstance.get("/api/referral/research-referral-participant-list", { params: queryParams });
    
    return response.data;
  },
  printResearchReferral: async (researchReferralId) => {
    const response = await http.backendInstance.get(`/api/referral/research-referral-print/${researchReferralId}`);
    
    return response.data;
  },

};

export default referralService;
