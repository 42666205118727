// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-wrapper {
  width: 100%;
  display: block;
  max-height: 1000px;
  min-height: 300px;
  padding: 1rem 0;
}

.demo-editor {
  max-height: 1000px;
  min-height: 300px;
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
}

.small-editor {
  height: 110px;
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
}

.small-wrapper {
  width: 100%;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/richTextEditor/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;AACF","sourcesContent":[".demo-wrapper {\n  width: 100%;\n  display: block;\n  max-height: 1000px;\n  min-height: 300px;\n  padding: 1rem 0;\n}\n\n.demo-editor {\n  max-height: 1000px;\n  min-height: 300px;\n  border: 1px solid #f1f1f1;\n  padding: 5px;\n  border-radius: 2px;\n}\n\n.small-editor {\n  height: 110px;\n  border: 1px solid #f1f1f1;\n  padding: 5px;\n  border-radius: 2px;\n}\n\n.small-wrapper {\n  width: 100%;\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
