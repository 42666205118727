import React from "react";
import http from "../../../api/http";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteGroupDialog from "./DeleteGroupDialog";
import { useDispatch, useSelector } from "react-redux";
import { setMoreInformationGroups, setMoreInformationGroupsUpdateDate } from "../../../redux/actions/cms";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { MoreInformationCmsGroup } from "../../../pages/cms/moreInformation/Group";
import { reorder } from "../../../pages/cms/utilities";

export const MoreInformationCmsDraggableGroups = () => {

  // fetch all groups
  const [isLoading, setIsLoading] = React.useState(true);

  const groups = useSelector(state => state.Cms.moreInformationGroups);

  const [isDeleteGroupDialogOpen, setIsDeleteGroupDialogOpen] = React.useState(false);

  const [groupIdToDelete, setGroupIdToDelete] = React.useState(null);

  const dispatch = useDispatch();

  const onDragEnd = async ({ destination, source }) => {
    if (!destination || destination.index === source.index) return;
    //change order of groups
    const newItems = reorder(groups, source.index, destination.index);
    dispatch(setMoreInformationGroups(newItems));
    await saveGroupsOrder(source.index + 1, destination.index + 1);
  };

  const saveGroupsOrder = async (source, destination) => {
    try {
      await http.backendInstance.put("/api/cms/ordermoreinfogroup", {
        source,
        destination
      });
      dispatch(setMoreInformationGroupsUpdateDate(new Date()));
    } catch (error) {
      console.log(error);
    }
  }

  const fetchCmsGroups = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/public/moreinfo");
      dispatch(setMoreInformationGroups(data));
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const deleteCmsGroup = async () => {
    try {
      await http.backendInstance.delete(`/api/cms/moreinfogroup/${groupIdToDelete}`);
      dispatch(setMoreInformationGroupsUpdateDate(Date.now()));
      dispatch(setMoreInformationGroups(groups.filter(item => item.id !== groupIdToDelete)));
      setIsDeleteGroupDialogOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteGroupDialogOpen(false);
    setGroupIdToDelete(null);
  };

  const handleDialogOpen = (id) => {
    setIsDeleteGroupDialogOpen(true);
    setGroupIdToDelete(id);
  };

  React.useEffect(() => {
    fetchCmsGroups();
  }, []);


  if (isLoading) {
    return (
      <>
        <Grid container>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <DeleteGroupDialog
        open={isDeleteGroupDialogOpen}
        onClose={handleCloseDeleteDialog}
        onClick={deleteCmsGroup}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {groups.map((item, idx) => (
                <Draggable key={item.id} draggableId={String(item.id)} index={idx}>
                  {(provided, snapshot) => (
                    <MoreInformationCmsGroup
                      ref={provided.innerRef}
                      dragProps={{ ...provided.dragHandleProps }}
                      draggableProps={{ ...provided.draggableProps }}
                      handleDialogOpen={handleDialogOpen}
                      group={item}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};