import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import http from "../../../api/http";
import { TabContext } from "@mui/lab";
import { CustomBreadCrumbs, TabPanel, TitleForm } from "../../../MUI_components";
import { FormProvider, useForm } from "react-hook-form";
import { MoreInformationCmsEditGroupForm } from "../../../components/cms/moreInformation/EditGroupForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMoreInformationGroupsUpdateDate } from "../../../redux/actions/cms";
import { useTheme } from "@emotion/react";

//Component for editing the Group
export const MoreInformationCmsEditGroupView = () => {

  const form = useForm();

  const [value, setValue] = React.useState(0);
  const handleChange = (_, value) => {
    setValue(value);
  };

  const updateDate = useSelector(state => state.Cms.moreInformationUpdateDate);

  const { groupId } = useParams();

  const link = [
    { to: "/cms/more-information", text: "Satura pārvaldība - Vairāk informācijas" },
    { to: `/cms/more-information/groups/${groupId}/edit`, text: "Labot grupu" }];

  const moreInformationGroupItem = useSelector(state => state.Cms.moreInformationGroups);

  const group = moreInformationGroupItem.find((group) => group.id == groupId);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmitForm = async (data, e) => {
    try {
      await http.backendInstance.put(`/api/cms/moreinfogroup`, data);
      dispatch(setMoreInformationGroupsUpdateDate(Date.now()));
      navigate("/cms/more-information");
    } catch (error) {
      console.log(error);
    }
  };

  const theme = useTheme();

  const { main } = theme.palette.error;

  const { formState: { errors } } = form;

  const hasFirstTabError = () => {
    return errors["groupTitleLv"] !== undefined;
  };

  const hasSecondTabError = () => {
    return errors["groupTitleEn"] !== undefined;
  };

  const hasThirdTabError = () => {
    return errors["groupTitleRu"] !== undefined;
  };

  const getIndicatorColor = () => {
    const tabIndicatorColor = theme.palette.error.main;
    switch (value) {
      case 0:
        if (hasFirstTabError()) return tabIndicatorColor;
        break;
      case 1:
        if (hasSecondTabError()) return tabIndicatorColor;
        break;
      case 2:
        if (hasThirdTabError()) return tabIndicatorColor;
        break;
      default:
        return "";
    }
  };

  const { groupTitleLv, groupTitleEn, groupTitleRu } = group || {};

  return (
    <>
      <Box display={'flex'} alignItems={'flex-start'}>
        <CustomBreadCrumbs links={link} />
      </Box>
      <Grid item xs={12}>
        <TitleForm
          title={"Labot grupu"}
          titleCode={"FRM.INFOGR.IL"}
          dateChanged={`${moment(updateDate).format("DD.MM.YYYY. HH:mm:ss")}`}
        />
      </Grid>
      <form onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormProvider {...form}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabContext value={String(value)}>
              <Tabs
                TabIndicatorProps={{
                  style: { backgroundColor: getIndicatorColor() },
                }}
                value={value}
                onChange={handleChange}
                aria-label="language tabs diff"
              >
                <Tab
                  label={<Typography color={hasFirstTabError() ? main : ""}>Lv</Typography>}
                  {...a11yProps(0)} />
                <Tab
                  label={<Typography color={hasSecondTabError() ? main : ""}>EN</Typography>}
                  {...a11yProps(1)} />
                <Tab
                  label={<Typography color={hasThirdTabError() ? main : ""}>RU</Typography>}
                  {...a11yProps(2)} />
              </Tabs>
            </TabContext>
          </Box>
          <input {...form.register("id")} value={groupId} hidden />
          <TabPanel value={value} index={0}>
            <MoreInformationCmsEditGroupForm name={"groupTitleLv"} defaultValue={groupTitleLv} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MoreInformationCmsEditGroupForm name={"groupTitleEn"} defaultValue={groupTitleEn} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MoreInformationCmsEditGroupForm name={"groupTitleRu"} defaultValue={groupTitleRu} />
          </TabPanel>
        </FormProvider>
      </form>
    </>
  );
};

export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};
