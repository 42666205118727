import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ROLES from "../../../roles/roles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grow from "@mui/material/Grow";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import {
  setSystemNotificationError,
  unsetSystemNotifications,
} from "../../../redux/actions/sysNotification";
import useRightTranslation from "../../../hooks/useRightTranslation";
import Box from "@mui/material/Box";
import Info from "@mui/icons-material/Info";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { useTheme } from "@emotion/react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import SysNotificationDeleteDialog from "./SysNotificationDeleteDialog";
import systemNotificationService from "../../../services/system-notification-service";
import { Toast } from "../../../MUI_components";
import CONSTANTS from "../../../constants";
import timeUtil from "../../../utils/time-util";

import { SystemNotificationPublicationPlaceEnumMapLV, SystemNotificationTypeEnum } from "../../../enums";

const SysNotification = ({ notification, handleNeedToUpdate, readOnly = false }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((x) => x.Auth.role);
  const { GetRightTranslation } = useRightTranslation();

  const [toastInfo, setToastInfo] = useState({
    showToast: false,
    message: "",
    failure: false,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  //remove notification locally from redux store
  const handleCloseNotification = () => {
    dispatch(unsetSystemNotifications(notification.id));
  };

  const handleEdit = () => {
    navigate(`/cms/system-notifications/edit/${notification.id}`);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await systemNotificationService.deleteSystemNotification(notification.id);
      dispatch(unsetSystemNotifications(notification.id));
      setToastInfo({ ...toastInfo, message: "Ziņojums dzēsts", showToast: true, failure: false });
      if (handleNeedToUpdate)
        handleNeedToUpdate();
    } catch (error) {
      console.error(error?.message);
      dispatch(setSystemNotificationError(true));
      setToastInfo({ ...toastInfo, message: "Kļūda! Ziņojums netika dzēsts!", showToast: true, failure: true });
    }
  };

  const {
    contentLv,
    contentEn,
    contentRu,
    titleLv,
    titleEn,
    titleRu
  } = notification || {};


  function backgroundColor(type) {
    switch (type) {
      case SystemNotificationTypeEnum.Informational:
        return CONSTANTS.SYSTEM_NOTIFICATION_COLORS.INFORMATIONAL;
      case SystemNotificationTypeEnum.Caution:
        return CONSTANTS.SYSTEM_NOTIFICATION_COLORS.CAUTION;
      default:
        return theme.palette.background.default;
    }
  }

  function sysNotificationIcon(type) {
    switch (type) {
      case SystemNotificationTypeEnum.Informational:
        return (<Info color="primary" fontSize={"16px"} />);
      case SystemNotificationTypeEnum.Caution:
        return (
          <NotificationImportantIcon
            color={theme.text.dark_yellow}
            fontSize={"16px"}
          />
        );
      default:
        return "";
    }
  }

  function sysNotificationDateText() {
    return `${timeUtil.date(notification.validFrom)} - ${timeUtil.date(notification.validTo)}`;
  }

  function sysNotificationPublicationPlaceText() {
    return `${SystemNotificationPublicationPlaceEnumMapLV.get(notification.publicationPlace)},`;
  }

  function textColor(type) {
    switch (type) {
      case SystemNotificationTypeEnum.Caution:
        return theme.text.dark_yellow;
      case SystemNotificationTypeEnum.Informational:
          return theme.text.dark_color;
      default:
        return theme.text.dark_color;
    }
  }

  function showSysNotificationActions() {
    return userRole?.name === ROLES.Admin && !readOnly;
  }

  return (
    <>
      <SysNotificationDeleteDialog
        open={isDeleteModalOpen}
        setIsOpen={()=>setIsDeleteModalOpen(false)}
        handleContinue={handleDelete}
      />
      <Grow in={true} timeout={1000}>
        <Grid
          container
          component={Box}
          backgroundColor={backgroundColor(notification.type)}
          display={{ xs: "none", lg: "block" }}
          boxShadow={"0px 2px 15px rgba(51, 51, 51, 0.15);"}
          borderRadius={1}
          direction="column"
          gap={2}
          px={1}
          py={1}
        >
          <Grid container alignItems={"center"} item xs={12}>
            <Grid
              item
              xs={10}
              justifyContent={"flex-start"}
              display={"flex"}
              alignItems={"center"}
              gap={".5rem"}
            >
              {sysNotificationIcon(notification.type)}
              <Typography
                variant="h4"
                color={textColor(notification.type)}
              >
                {GetRightTranslation(titleLv, titleEn, titleRu)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item >
            <Box sx={{pl: 3 }}>
              {parse(GetRightTranslation(contentLv, contentEn, contentRu))}
            </Box>
          </Grid>
          {showSysNotificationActions() && (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mx={3}
            >
              <Grid
                item
                xs={9}
                alignItems={"center"}
                display="flex"
                gap={".5rem"}
              >
                <Typography variant={"h5"} color={theme.text.dark_color}>
                  {sysNotificationPublicationPlaceText()}
                </Typography>
                <Typography variant={"h5"}>
                  {sysNotificationDateText()}
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" gap={1}>
                <Button
                  variant={"text"}
                  onClick={handleEdit}
                  startIcon={<EditOutlinedIcon color={theme.text.link_color} />}
                >
                  Labot
                </Button>
                <Button
                  variant={"text"}
                  onClick={handleDeleteModalOpen}
                  startIcon={
                    <DeleteOutlineIcon color={theme.text.link_color} />
                  }
                >
                  Dzēst
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grow>
      <Toast
        showToast={toastInfo.showToast}
        message={toastInfo.message}
        failure={toastInfo.failure}
        handleClose={()=>setToastInfo({ ...toastInfo, showToast: false })}
      />
    </>
  );
};

export default SysNotification;
