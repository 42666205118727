import ROLE from "../../roles/roles";
import RIGHTS from "../../rights_enum";
import React, { useState } from "react";
import { Link, Box, Stack, Typography, Toolbar, MenuItem, Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Logo from "./img/Decide Logo.png";
import DropdownMenu from "../dropdownMenu/dropdownMenu";
import classes from "./header.module.scss";
import HamburgerMenu from "../../MUI_components/HamburgerMenu";
import { useTranslation } from "../../hooks/useTranslation";
import useRole from "../../hooks/useRole";
import { useAccessToken } from "../../hooks";
import Hoverable from "../dropdownMenu/Hoverable";
import { ResearchStatusEnum } from "../../enums"

export const HeaderDown = () => {
  const {
    MainMenuPersons,
    MainMenuPersonsList,
    MainMenuResearch,
    MainMenuPersonsAddNew,
    MainMenuMoreInfo,
    MainMenuContacts,
    MainMenuAboutUs,
    MainMenuReferral,
    MainMenuReferralAddNew,
    MainMenuReferralList,
    MainMenuSurvey,
    MainMenuSignedConsent,
  } = useTranslation();

  const {
    role: { name },
  } = useRole();


  const { rights, isAuthenticated } = useAccessToken();
  const surveySelectRoutePaths = ["/surveys", "/surveys/participant-surveys", "/questions"];
  const personSelectRoutePaths = ["/persons", "/persons/add"];
  const referralSelectRoutePaths = ["/referrals", "/referrals/add"];
  const researchSelectRoutePaths = ["/public/researches", "/researches", "/permissions", "/consents/user-submitted-consents", "/biobank"];

  const isCurrentUserAuthorized = sessionStorage.getItem("isAuthenticated")
    ? true
    : false;

  const [isUserSelectedMoreInfoTab, setIsUserSelectedMoreInfoTab] = useState(false);
  const [isUserSelectedContactsTab, setIsUserSelectedContactsTab] = useState(false);
  const [isUserSelectedAboutTab, setIsUserSelectedAboutTab] = useState(false);
  const [isUserSelectedSignedConsentsTab, setIsUserSelectedSignedConsentsTab] = useState(false);

  if ((name === ROLE.Researcher || name === ROLE.Admin || name === ROLE.Doctor) &&
    rights.includes(RIGHTS.RES_READ)) {
  }

  function showPermissionElements() {
    return name === ROLE.Researcher || name === ROLE.Admin;
  }

  function showListOfConsentsSubmittedByUser() {
    return name === ROLE.Participant && isCurrentUserAuthorized;
  }


  function showResearcherSurveyList() {
    return (
      name === ROLE.Researcher ||
      name === ROLE.Admin ||
      rights.includes(RIGHTS.CanReadSurvey)
    );
  }

  function showParticipantSurveyList() {
    return name === ROLE.Participant;
  }

  function showQuestionList() {
    return (
      name === ROLE.Researcher ||
      name === ROLE.Admin ||
      rights.includes(RIGHTS.CanReadQuestion)
    );
  }

  function showBiobankConsentTab() {
    const allowedUserRoles = [ROLE.Participant, ROLE.Admin];

    return (
      allowedUserRoles.some((roleName) => roleName === name) &&
      isCurrentUserAuthorized
    );
  }

  function showSignedConsentTab() {
    const allowedUserRights = [
      RIGHTS.CanReadSignedConsentAll,
      RIGHTS.CanReadSignedConsentResearcher,
      RIGHTS.CanReadSignedConsentUser,
    ];

    return rights.some((right) => allowedUserRights.includes(right));
  }

  return (
    <>
    <Box className={classes.header_down} sx={{ flexGrow: 1 }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "4rem"
        }}
      >
        <Box className={classes.header__logo}>
          <Link component={RouterLink} to={isAuthenticated() ? "/dashboard" : "/"}>
            <img
              className={classes.logo__image}
              src={Logo}
              alt="decide logo"
            />
          </Link>
        </Box>
        <HamburgerMenu />
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "flex-end",
            alignItems: "baseline",
          }}
        >
          <Hoverable
            pathName={"/about"}
            isElementSelectedCallback={setIsUserSelectedAboutTab}
          >
            <LinkedTab
              selected={isUserSelectedAboutTab}
              navigateTo="/about"
              title={MainMenuAboutUs}
            />
          </Hoverable>
          <Hoverable includedInPaths={researchSelectRoutePaths}>
            <DropdownMenu
              id="research"
              menuName={MainMenuResearch}
              ariaControls={"research-menu"}
              ariaLabelledBy={"research-link"}
              dropdownItemsPaths={researchSelectRoutePaths}
            >
              {({ handleClose }) => (
                <div>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: "/public/researches",
                      search: `?status=${ResearchStatusEnum.Published}`,
                    }}
                    color="black"
                  >
                    <MenuItem onClick={handleClose}>
                      <Typography align="center">
                        {"Aktuālie pētījumi"}
                      </Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: "/public/researches",
                      search: `?status=${ResearchStatusEnum.Completed}`,
                    }}
                    color="black"
                  >
                    <MenuItem onClick={handleClose}>
                      {"Iepriekš veiktie pētījumi"}
                    </MenuItem>
                  </Link>
                  {(name === ROLE.Researcher ||
                    name === ROLE.Admin ||
                    name === ROLE.Doctor) &&
                    rights.includes(RIGHTS.RES_READ) && (
                      <Link component={RouterLink} color="black" to="/researches">
                        <MenuItem onClick={handleClose}>
                          Pētījumu saraksts
                        </MenuItem>
                      </Link>
                    )}
                  {showPermissionElements() && (
                    <Link component={RouterLink} color="black" to="/permissions">
                      <MenuItem onClick={handleClose}>
                        {"Piekrišanas elementu saraksts"}
                      </MenuItem>
                    </Link>
                  )}
                  {showListOfConsentsSubmittedByUser() && (
                    <Link
                      component={RouterLink}
                      color="black"
                      to="/consents/user-submitted-consents"
                    >
                      <MenuItem onClick={handleClose}>
                        {"Informēto piekrišanu saraksts"}
                      </MenuItem>
                    </Link>
                  )}
                  {showBiobankConsentTab() && (
                    <Link component={RouterLink} color="black" to="/biobank">
                      <MenuItem onClick={handleClose}>
                        {"Biobankas informētā piekrišana"}
                      </MenuItem>
                    </Link>
                  )}
                </div>
              )}
            </DropdownMenu>
          </Hoverable>
          {isCurrentUserAuthorized && (
            <Hoverable includedInPaths={surveySelectRoutePaths}>
              <DropdownMenu
                id="survey"
                menuName={MainMenuSurvey || "Anketas"}
                dropdownItemsPaths={surveySelectRoutePaths}
                ariaControls={"survey-menu"}
                ariaLabelledBy={"survey-link"}
              >
                {({ handleClose }) => (
                  <div>
                    {showResearcherSurveyList() && (
                      <Link component={RouterLink} color="black" to="/surveys">
                        <MenuItem onClick={handleClose}>Anketu saraksts</MenuItem>
                      </Link>
                    )}
                    {showParticipantSurveyList() && (
                      <Link component={RouterLink} color="black" to="/surveys/participant-surveys">
                        <MenuItem onClick={handleClose}>Manas anketas</MenuItem>
                      </Link>
                    )}
                    {showQuestionList() && (
                      <Link component={RouterLink} color="black" to="/questions">
                        <MenuItem onClick={handleClose}>Jautājumu saraksts</MenuItem>
                      </Link>
                    )}
                  </div>
                )}
              </DropdownMenu>
            </Hoverable>
          )
          }
          {isCurrentUserAuthorized && showSignedConsentTab() && (
            <Hoverable
              pathName="/signed-consents"
              isElementSelectedCallback={setIsUserSelectedSignedConsentsTab}
            >
              <LinkedTab
                selected={isUserSelectedSignedConsentsTab}
                navigateTo="/signed-consents"
                title={MainMenuSignedConsent || "PARAKSTĪTĀS PIEKRIŠANAS"}
              />
            </Hoverable>
            )
          }

          {(isCurrentUserAuthorized && (name === ROLE.Admin || name === ROLE.Doctor)) && (
            <Hoverable includedInPaths={personSelectRoutePaths} >
              <DropdownMenu
                id="persons"
                menuName={MainMenuPersons}
                dropdownItemsPaths={personSelectRoutePaths}
                ariaControls={"person-menu"}
                ariaLabelledBy={"person-link"}
              >
                {({ handleClose }) => {
                  return (
                    <div>
                      {(rights.includes(RIGHTS.PERS_READ) ||
                        rights.includes(RIGHTS.PERS_READALL)) && (
                          <Link component={RouterLink} to="/persons" color="black">
                            <MenuItem onClick={handleClose}>
                              <Typography
                                align="center"
                              >
                                {MainMenuPersonsList}
                              </Typography>
                            </MenuItem>
                          </Link>
                        )}
                      {rights.includes(RIGHTS.PERS_CREATE) && (
                        <Link
                          component={RouterLink}
                          color="black"
                          to="/persons/add"
                        >
                          <MenuItem onClick={handleClose}>
                            {MainMenuPersonsAddNew}
                          </MenuItem>
                        </Link>
                      )}
                    </div>
                  );
                }}
              </DropdownMenu>
            </Hoverable>
          )}
          {(isCurrentUserAuthorized && (name === ROLE.Admin || name === ROLE.Researcher)) && (
            <Hoverable includedInPaths={referralSelectRoutePaths} >
              <DropdownMenu
                id="referral"
                menuName={MainMenuReferral}
                ariaControls={"referral-menu"}
                ariaLabelledBy={"referral-link"}
                dropdownItemsPaths={referralSelectRoutePaths}
              >
                {({ handleClose }) => {
                  return (
                    <div>
                      {rights.includes(RIGHTS.REF_READ) && (
                        <Link
                          component={RouterLink}
                          to="/referrals"
                          color="black"
                        >
                          <MenuItem onClick={handleClose}>
                            <Typography
                              align="center"
                              fontFamily="Segoe UI"
                            >
                              {MainMenuReferralList}
                            </Typography>
                          </MenuItem>
                        </Link>
                      )}
                      {rights.includes(RIGHTS.REF_EDIT) && (
                        <Link
                          component={RouterLink}
                          color="black"
                          to="/referrals/add"
                        >
                          <MenuItem onClick={handleClose}>
                            <Typography
                              align="center"
                              fontFamily="Segoe UI"
                            >
                              {MainMenuReferralAddNew}
                            </Typography>
                          </MenuItem>
                        </Link>
                      )}
                    </div>
                  );
                }}
              </DropdownMenu>
            </Hoverable>
          )}
          <Hoverable
            pathName={"/more-information"}
            isElementSelectedCallback={setIsUserSelectedMoreInfoTab}
          >
            <LinkedTab
              selected={isUserSelectedMoreInfoTab}
              navigateTo="/more-information"
              title={MainMenuMoreInfo}
            />
          </Hoverable>
          <Hoverable
            pathName={"/contacts"}
            isElementSelectedCallback={setIsUserSelectedContactsTab}
          >
            <LinkedTab
              selected={isUserSelectedContactsTab}
              navigateTo="/contacts"
              title={MainMenuContacts}
            />
          </Hoverable>
        </Stack>
      </Toolbar>

    </Box>
          <Divider />
          </>
  );
};


function LinkedTab({
  selected = false,
  navigateTo,
  title
}) {

  const selectedTabColor = "rgba(23, 84, 134, 1)";

  return (
    <Link
      color={selected ? selectedTabColor : "black"}
      component={RouterLink}
      to={navigateTo}
      className={classes.link}
    >
      <Typography
        align="center"
        sx={{ "&:hover": { color: selectedTabColor } }}
        fontFamily="Segoe UI"
      >
        {title}
      </Typography>
    </Link>
  );
}