import axios from "axios";
import { refreshAccessToken } from "../services/auth-service";

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const backendInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

backendInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.status === 401 && !error.config._retry) {
      error.config._retry = true;
      try {
        await refreshAccessToken();
        return backendInstance(error.config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const auditInstance = axios.create({
  baseURL: process.env.REACT_APP_AUDIT_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

backendInstance.interceptors.response.use((response) => {
  const { data: { data } } = response;
  if (response.data.hasOwnProperty("data")) response.data = data;
  return Promise.resolve(response);
});

authInstance.interceptors.response.use((response) => {
  const {
    data: { data },
  } = response;
  response.data = data;

  return Promise.resolve(response);
});


export default {
  authInstance,
  backendInstance,
  auditInstance,
  adminInstance,
};
