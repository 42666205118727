import http from "../api/http";

const resultService = {
  createResearchResult: async (resultToCreate, config = {}) => {
    const response = await http.backendInstance.post(
      "/api/result/research",
      resultToCreate,
      {
        ...config,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return response.data;
  },
  updateResearchResult: async (resultToUpdate, config = {}) => {
    const response = await http.backendInstance.put(
      "/api/result/research",
      resultToUpdate,
      {
        ...config,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return response.data;
  },
  publishResearchResults: async (researchResultId, abortControllerSignal = null) => {
    const response = await http.backendInstance.put(
      `/api/result/publish/${researchResultId}`,
      { signal: abortControllerSignal }
    );

    return response.data;
  },
  sendForPublicationResearchResults: async (researchResultId, abortControllerSignal = null) => {
    const response = await http.backendInstance.put(
      `/api/result/send-for-publication/${researchResultId}`,
      {},
      { signal: abortControllerSignal }
    );

    return response.data;
  },
  getResearchResults: async (researchId, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      `/api/result/research/${researchId}`,
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },
  createUserResult: async (userResultData, abortControllerSignal = null) => {

    const response = await http.backendInstance.post(
      "/api/result/add-participant-results",
      userResultData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        signal: abortControllerSignal
      }
    );

    return response.data;
  },
  publishUserResults: async (publishUserResultsData, abortControllerSignal = null) => {
    const response = await http.backendInstance.put(
      "/api/result/publish-user-results", 
      publishUserResultsData,
      { signal: abortControllerSignal }
    );

    return response.data;
  },
  getUserResults: async (queryParams, abortControllerSignal = null) => {
    const response = await http.backendInstance.get("/api/result/research-users-results-list", {
      params: { ...queryParams },
      signal: abortControllerSignal
    });

    return response.data;
  },
  getParticipantUserResults: async (queryParams, abortControllerSignal = null) => {
    const response = await http.backendInstance.get("/api/result/user-results", {
      params: { ...queryParams },
      signal: abortControllerSignal
    });

    return response.data;
  },
  sendUserResultQuestion: async (questionData, abortControllerSignal = null) => {
    const response = await http.backendInstance.post("/api/result/send-question", questionData, { signal: abortControllerSignal });

    return response.data;
  },
  deleteAttachment: async (userResultId, abortControllerSignal = null) => {
    const response = await http.backendInstance.delete(`/api/result/attachments/${userResultId}`, { signal: abortControllerSignal });

    return response.data;
  }
};

export default resultService;
