const ConsentTypeEnum = Object.freeze({
  Informed: 1,
  Biobank: 2,
});

const ConsentTypeMapLv = new Map([
  [ConsentTypeEnum.Informed, "Informētā piekrišana"],
  [ConsentTypeEnum.Biobank, "Biobankas piekrišana"],
]);

export { ConsentTypeEnum, ConsentTypeMapLv };
