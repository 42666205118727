import http from "../api/http";
import serviceUtil from "../utils/service-util";

const questionService = {
  getQuestions: async (queryParams, abortControllerSignal = null) => {
    if (!serviceUtil.isObject(queryParams))
      return [];

    queryParams = serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);

    const response = await http.backendInstance.get("/api/question/list",
      {
        params: { ...queryParams },
        signal: abortControllerSignal
      }
    );

    return response.data;
  },
  getQuestionById: async (questionId) => {
    const response = await http.backendInstance.get(`/api/question/${questionId}`);

    return response.data;
  },
  getQuestionsSelectListItems: async (signal) => {
    const response = await http.backendInstance.get("/api/question/questions-select-list-items",
      {
        signal: signal,
      }
    );

    return response.data;
  },
  getQuestionResponseSelectListItems: async (answerLevel, signal) => {
    const response = await http.backendInstance.get("/api/question/answer-select-list-items",
      {
        params: {
          answerLevel: answerLevel
        },
        signal: signal
      }
    );

    return response.data;
  },
  getDataGroupSelectListItems: async (signal) => {
    const response = await http.backendInstance.get("/api/question/data-group-select-list-items",
      {
        signal: signal,
      }
    );

    return response.data;
  },
  getCollectionSelectListItems: async (signal) => {
    const response = await http.backendInstance.get("/api/question/collection-select-list-items",
      {
        signal: signal,
      }
    );

    return response.data;
  },
  getThemeSelectListItems: async (signal) => {
    const response = await http.backendInstance.get("/api/question/theme-select-list-items",
      {
        signal: signal,
      }
    );

    return response.data;
  },
  getSeriesSelectListItems: async (signal) => {
    const response = await http.backendInstance.get("/api/question/series-select-list-items",
      {
        signal: signal,
      }
    );

    return response.data;
  },
};

export default questionService;