import { useLocation } from "react-router-dom";

const useURL = () => {
  const location = useLocation();
  const currentFullURL = window.location.href;
  const originURL = window.location.origin;

  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;

    return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  };

  const getCurrentPathnameWithSearchValues = () => {

    return `${location.pathname}${location?.search?.length ? `${location.search}` : ""}`;
  };

  return {
    getBaseUrl,
    currentFullURL,
    originURL,
    getCurrentPathnameWithSearchValues
  }
};

export default useURL;