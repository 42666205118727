import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import CONSTANTS from "../constants";
import "moment/locale/lv";
moment.locale(CONSTANTS.DEFAULT_LOCALE);

const CustomDatePickerWithAge = ({
  name,
  label,
  defaultValue,
  inputLabelProps,
  inputProps,
  testId,
  ...props
}) => {
  const minDate = moment(CONSTANTS.MIN_DATE);
  const maxDate = moment(new Date());

  const [date, setDate] = useState(defaultValue ? moment(defaultValue) : maxDate);
  const [age, setAge] = useState(null);

  const {
    formState: { errors },
    register,
    setValue,
    control,
  } = useFormContext();

  const handleDate = (selectedDate) => {
    const newDate = selectedDate ? moment(selectedDate) : null;
    const age = moment().diff(newDate, "years");
    setDate(newDate);
    setAge(age);
    setValue(name, newDate ? newDate.toDate() : null, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const shouldDisableYear = (year) => {
    return moment().diff(year, "years") < 18;
  };

  useEffect(() => {
    register(name, {
      valueAsDate: true,
      validate: (value) =>
        moment().diff(value, "years") > 17 ||
        "Lietotājam ir jābūt vismaz 18 gadus vecam",
    });
  }, [register, name]);

  useEffect(() => {
    const initialDate = defaultValue ? moment(defaultValue) : maxDate;
    setDate(initialDate);
    const initialAge = moment().diff(initialDate, "years");
    if (initialAge > 0) setAge(initialAge);
  }, [defaultValue, maxDate]);

  return (
    <>
      <Box
        display="flex"
        gap="1.5rem"
        justifyContent="space-between"
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Box flex="1">
          <FormControl size="small" fullWidth {...props}>
            <DatePicker
              label={label}
              InputProps={{ ...inputProps, "data-testid": testId }}
              onChange={handleDate}
              {...props}
              minDate={minDate}
              maxDate={maxDate}
              shouldDisableYear={shouldDisableYear}
              value={date}
              inputFormat={CONSTANTS.DATE_FORMAT}
              mask={"__.__.____"}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={inputLabelProps}
                  size="small"
                  {...params}
                  error={Object.hasOwn(errors, name)}
                />
              )}
            />
            <FormHelperText error>
              {errors.dateBirth ? errors.dateBirth.message : ""}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex="1"
        >
          {age && (
            <>
              <Typography variant="h5">Aprēķinātais vecums:</Typography>
              <Typography
                variant="h5"
                color={errors.dateBirth ? "error" : "green"}
              >
                {age} gadi
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CustomDatePickerWithAge;
