import axios from "axios";
import { configureStore } from '../redux/store';
import {
  setAccessToken,
  setAuthenticated,
  setAccessRights,
  setTokenLifeTime,
  setAssignedGroupsCode,
  setSingleUserRole
} from "../redux/actions/auth";
import { UserRoleCodeFromNameMap, LocalStorageKey, SessionStorageKey } from "../enums";

let isRefreshing = false;
let failedRequests = [];

function getCurrentSessionAccessToken() {
  return localStorage.getItem(LocalStorageKey.AccessToken)?.length ? localStorage.getItem(LocalStorageKey.AccessToken) : null;
}

async function refreshToken() {
  const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/api/auth/refreshToken`, {}, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getCurrentSessionAccessToken()}`
    }
  });

  const { accessToken, rights, tokenLifeTime, groupsAssignedCode } = response.data.data;
  configureStore.dispatch(setAccessToken(accessToken));
  configureStore.dispatch(setAccessRights(rights));
  configureStore.dispatch(setTokenLifeTime(tokenLifeTime));
  configureStore.dispatch(setAuthenticated(true));
  configureStore.dispatch(setAssignedGroupsCode(groupsAssignedCode));
  const userRoleName = sessionStorage.getItem(SessionStorageKey.Role);
  configureStore.dispatch(setSingleUserRole(
    {
      code: UserRoleCodeFromNameMap.get(userRoleName),
      name: userRoleName
    }
  ));

  return accessToken;
}

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});


authInstance.interceptors.request.use(
  function (config) {
    if (getCurrentSessionAccessToken()) {
      config.headers.Authorization = `Bearer ${getCurrentSessionAccessToken()}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authInstance.interceptors.response.use(
  response => {
    const {
      data: { data },
    } = response;
    response.data = data;

    return Promise.resolve(response);
  },
  async error => {
    const originalRequest = error.config
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // if a token refresh is already in progress, queue the request
        return new Promise((resolve, reject) => {
          failedRequests.push({ resolve, reject });
        }).then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        }).catch((err) => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newAccessToken = await refreshToken();
        isRefreshing = false;

        // retry all queued requests with the new token
        failedRequests.forEach((prom) => prom.resolve(newAccessToken));
        failedRequests = [];

        // retry the original request
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (error) {
        // clear the queue and reject all requests if the refresh fails
        failedRequests.forEach((prom) => prom.reject(error));
        failedRequests = [];
        isRefreshing = false;

        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const backendInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true,
});


backendInstance.interceptors.request.use(
  function (config) {
    if (getCurrentSessionAccessToken()) {
      config.headers.Authorization = `Bearer ${getCurrentSessionAccessToken()}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

backendInstance.interceptors.response.use(
  response => {
    const { data: { data } } = response;
    if (response.data.hasOwnProperty("data"))
      response.data = data;

    return Promise.resolve(response);
  },
  async error => {
    const originalRequest = error.config
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // If a token refresh is already in progress, queue the request
        return new Promise((resolve, reject) => {
          failedRequests.push({ resolve, reject });
        }).then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;

          return axios(originalRequest);
        }).catch((err) => {

          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newAccessToken = await refreshToken();
        isRefreshing = false;

        // retry all queued requests with the new token
        failedRequests.forEach((prom) => prom.resolve(newAccessToken));
        failedRequests = [];

        // retry the original request
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        return axios(originalRequest);
      } catch (error) {
        // clear the queue and reject all requests if the refresh fails
        failedRequests.forEach((prom) => prom.reject(error));
        failedRequests = [];
        isRefreshing = false;

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

const auditInstance = axios.create({
  baseURL: process.env.REACT_APP_AUDIT_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const http = {
  authInstance,
  backendInstance,
  auditInstance,
  adminInstance
}

export default http;
