import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { GridOverlay } from "@mui/x-data-grid";

export const NoRowsOverlay = () => {
  return (
    <GridOverlay>
      <Box
        width={"100%"}
        mt={1}
        position={"relative"}
        top={"50%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography>Nav ierakstu</Typography>
      </Box>
    </GridOverlay>
  );
};
