import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import moment from "moment";
import { useFormContext, Controller } from "react-hook-form";
import CONSTANTS from "../constants";
import { Box, Grid, FormHelperText, FormControl, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CustomDatePicker = ({
  title = "",
  label,
  name,
  defaultValue = null,
  required,
  rules,
  fullWidth,
  disablePast = false,
  disableDatePicker = false,
  useUTC = false,
  withEndOfDay = false, // by default is set startOf the day
  ...props
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const theme = useTheme();

  const primaryTextColor = theme.palette.text.primary;
  const errorColor = theme.palette.error.main;


  function getDefaultMomentMinDate() {
    return getMomentInstance(CONSTANTS.MIN_DATE).startOf("day");
  }

  function getDefaultValue() {
    if (!defaultValue)
      return null;

    return getMomentInstance(defaultValue);
  }

  function getMomentInstance(dateString) {
    return useUTC ? moment.utc(dateString) : moment(dateString);
  }

  function getValueOfDatePicker(date) {
    if (!date)
      return null;

    if (date && moment.isMoment(date))
      return setMomentTime(date);

    return setMomentTime(getMomentInstance(date));
  }

  function setMomentTime(momentInstance) {
    if (!moment.isMoment(momentInstance))
      return null;

    return withEndOfDay ? momentInstance.endOf("day") : momentInstance.startOf("day")
  }

  return (
    <>
      <FormControl size="small" fullWidth={fullWidth} {...props}>
        <Controller
          control={control}
          name={name}
          defaultValue={getDefaultValue()}
          rules={{ required: required ? "Lauks ir obligāts" : false, ...rules }}
          render={({ field: { onBlur, onChange, value } }) => (
            <Box>
              <Grid container direction="column">
                {(title?.length > 0) && (
                  <Grid item>
                    <Typography sx={{ color: errors[name] ? errorColor : primaryTextColor }}>
                      {title}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} xl={4}>
                  <DatePicker
                    disablePast={disablePast}
                    disabled={disableDatePicker}
                    timezone={useUTC ? "UTC" : "default"}
                    label={label}
                    size="small"
                    minDate={getDefaultMomentMinDate().startOf("day")}
                    onChange={(date) => {
                      let newDate = null;
                      if (date && date.isValid())
                        newDate = setMomentTime(date);

                      onChange(newDate);
                      onBlur();
                    }}
                    value={
                      getValueOfDatePicker(value)
                    }
                    inputFormat={CONSTANTS.DATE_FORMAT}
                    {...props}
                    textField={(params) => (
                      <TextField
                        fullWidth={fullWidth}
                        size="small"
                        {...params}
                        error={Boolean(errors[name])}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        />
        <FormHelperText error>{errors[name]?.message}</FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomDatePicker;
