import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import http from "../../../api/http";
import Typography from "@mui/material/Typography";
import React from "react";
import moment from "moment/moment";
import { TabContext } from "@mui/lab";
import { CustomBreadCrumbs, TabPanel, TitleForm } from "../../../MUI_components";
import { FormProvider, useForm } from "react-hook-form";
import { FAQCmsAddGroupForm } from "../../../components/cms/faq/AddGroupForm";
import { Link, useNavigate } from "react-router-dom";
import { setFaqUpdateDate } from "../../../redux/actions/cms";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

//Component for adding a new Group

const link = [
  { to: "/cms/faq", text: "Satura pārvaldība - Biežāk uzdotie jautājumi" },
  { to: "/cms/faq/question-groups/add", text: "Pievienot grupu" }
];

const FAQAddGroupView = () => {
  const form = useForm();

  const [value, setValue] = React.useState(0);

  const handleChange = (_, value) => {
    setValue(value);
  };

  const dispatch = useDispatch();

  const updateDate = useSelector(state => state.Cms.faqUpdateDate);

  const navigate = useNavigate();
  const handleSubmitForm = async (data, e) => {
    try {
      const { nameLv, nameEn, nameRu } = data;
      const body = { groupTitleLv: nameLv, groupTitleEn: nameEn, groupTitleRu: nameRu };
      await http.backendInstance.post("/api/cms/faqgroup", body);
      dispatch(setFaqUpdateDate(Date.now()));
      navigate("/cms/faq");
    } catch (error) {
      console.log(error);
    }
  };

  const theme = useTheme();

  const { main } = theme.palette.error;

  const { formState: { errors } } = form;

  const hasFirstTabError = () => {
    return errors["nameLv"] !== undefined;
  };

  const hasSecondTabError = () => {
    return errors["nameEn"] !== undefined;
  };

  const hasThirdTabError = () => {
    return errors["nameRu"] !== undefined;
  };

  const getIndicatorColor = () => {
    const tabIndicatorColor = theme.palette.error.main;
    switch (value) {
      case 0:
        if (hasFirstTabError()) return tabIndicatorColor;
        break;
      case 1:
        if (hasSecondTabError()) return tabIndicatorColor;
        break;
      case 2:
        if (hasThirdTabError()) return tabIndicatorColor;
        break;
      default:
        return "";
    }
  };

  return (
    <>
      <Box display={'flex'} alignItems={'flex-start'}>
        <CustomBreadCrumbs links={link} />
      </Box>
      <Grid item xs={12}>
        <TitleForm
          title={"Pievienot grupu"}
          titleCode={"FRM.FAQGR.IL"}
          dateChanged={`${moment(updateDate).format("DD.MM.YYYY. HH:mm:ss")}`}
        />
      </Grid>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmitForm)}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabContext value={String(value)}>
              <Tabs
                TabIndicatorProps={{
                  style: { backgroundColor: getIndicatorColor() },
                }}
                value={value}
                onChange={handleChange}
                aria-label="language tabs diff"
              >
                <Tab
                  label={<Typography color={hasFirstTabError() ? main : ""}>Lv</Typography>}
                  {...a11yProps(0)} />
                <Tab
                  label={<Typography color={hasSecondTabError() ? main : ""}>EN</Typography>}
                  {...a11yProps(1)} />
                <Tab
                  label={<Typography color={hasThirdTabError() ? main : ""}>RU</Typography>}
                  {...a11yProps(2)} />
              </Tabs>
            </TabContext>
          </Box>
          <TabPanel value={value} index={0}>
            <FAQCmsAddGroupForm name={"nameLv"} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FAQCmsAddGroupForm name={"nameEn"} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <FAQCmsAddGroupForm name={"nameRu"} />
          </TabPanel>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            my={2}
            gap={"1rem"}
          >
            <Button type="submit" variant="contained" color="success">
              Saglabāt
            </Button>
            <Button component={Link} to="/cms/faq">
              Atcelt
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default FAQAddGroupView;

export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};
