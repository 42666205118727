// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-link {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  position: relative;
  cursor: pointer;
}
.dropdown-link::after {
  content: "";
  width: 0;
  height: 2px;
  background-color: rgb(23, 84, 134);
  position: absolute;
  bottom: -16px;
  left: 0;
  transition: width 0.2s linear;
}
.dropdown-link--active {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown-link--active::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: rgb(23, 84, 134);
  position: absolute;
  bottom: -16px;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/dropdownMenu/dropdown.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,WAAA;EACA,QAAA;EACA,WAAA;EACA,kCAAA;EACA,kBAAA;EACA,aAAA;EACA,OAAA;EACA,6BAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,WAAA;EACA,WAAA;EACA,WAAA;EACA,kCAAA;EACA,kBAAA;EACA,aAAA;EACA,OAAA;AAAN","sourcesContent":[".dropdown-link {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  transition: all 0.2s linear;\n  position: relative;\n  cursor: pointer;\n\n  &::after {\n    content: \"\";\n    width: 0;\n    height: 2px;\n    background-color: rgba(23, 84, 134, 1);\n    position: absolute;\n    bottom: -16px;\n    left: 0;\n    transition: width 0.2s linear;\n  }\n\n  &--active {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    position: relative;\n\n    &::after {\n      content: \"\";\n      width: 100%;\n      height: 2px;\n      background-color: rgba(23, 84, 134, 1);\n      position: absolute;\n      bottom: -16px;\n      left: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
