import http from "../api/http";
import serviceUtil from "../utils/service-util";

const personConsentService = {
  getPersonConsentList: async (queryParams) => {
    if (!serviceUtil.isObject(queryParams)) return [];
    queryParams =
      serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);
    const response = await http.backendInstance.get("/api/personconsent/person-consents",
      {
        params: {
          ...queryParams,
        },
      }
    );

    return response.data;
  },
  createPersonConsent: async (personConsentToCreate) => {
    const response = await http.backendInstance.post("/api/personConsent/",
      {
        ...personConsentToCreate,
      }
    );

    return response.data;
  },
  getIdOfPersonConsentWhatUserFilledByConsentIdAndPersonId: async (
    consentId,
    personId,
    abortControllerSignal = null
  ) => {
    const response = await http.backendInstance.get(
      "/api/personConsent/submitted-person-consent-id",
      {
        params: { consentId, personId },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },

  getPersonConsentById: async (personConsentId, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      `/api/personConsent/${personConsentId}`,
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },

  refusePersonConsent: async (researchId, personId) => {
    const response = await http.backendInstance.delete("/api/personConsent",
      {
        data: { researchId, personId },
      }
    );

    return response.data;
  },

  isPersonSubmittedConsent: async (personId, consentId) => {
    const response = await http.backendInstance.get("/api/personConsent/is-person-submitted-consent",
      {
        params: { personId, consentId },
      }
    );

    return response.data;
  },

  hasPersonSubmittedAnyConsent: async (personId, consentType, abortControllerSignal = null) => {
    const response = await http.backendInstance.get("/api/personConsent/has-person-submitted-any-consent-previously",
      {
        params: { personId, consentType },
        signal: abortControllerSignal
      }
    );

    return response.data;
  },

  processPersonConsent: async (validationId, hasError, abortControllerSignal = null) => {
    const response = await http.backendInstance.post(
      "/api/personConsent/processPersonConsent",
      {
        validationId,
        hasError
      },
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  }
};

export default personConsentService;
