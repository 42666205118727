import http from "../api/http";
import serviceUtil from "../utils/service-util";

const permissionService = {
  getPermissionsForSearch: async (permissionTitleToSearch) => {
    const response = await http.backendInstance.get(
      "/api/permission/search-permissions",
      {
        params: { permissionTitleToSearch },
      }
    );

    return response.data;
  },
  getPermissionById: async (permissionId, signal) => {
    const response = await http.backendInstance.get(`/api/permission/${permissionId}`, { signal: signal });
    return response.data;
  },
  createPermission: async (permission) => {
    const response = await http.backendInstance.post("/api/permission", permission);

    return response.data;
  },
  updatePermission: async (permissionToUpdate) => {
    const response = await http.backendInstance.put("/api/permission", permissionToUpdate);

    return response.data;
  },
  deletePermissionById: async (permissionId) => {
    const response = await http.backendInstance.delete(`/api/permission/${permissionId}`);

    return response.data;
  },
  getPermissions: async (queryParams) => {
    if (!serviceUtil.isObject(queryParams))
      return [];

    queryParams = serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);
    const response = await http.backendInstance.get(`/api/permission/list`, {
      params: queryParams,
    });
    return response.data;
  },

  getQuestionsTextsFromSearch: async (questionTextToSearch) => {
    const response = await http.backendInstance.get("/api/permission/question-text-search", {
      params: { questionTextToSearch }
    });

    return response.data;
  },
  getResponsesTextsFromSearch: async (responseTextToSearch) => {
    const response = await http.backendInstance.get("/api/permission/responses-text-search", {
      params: { responseTextToSearch }
    });

    return response.data;
  },

  getConsentPermissionById: async (consentPermissionId, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      `/api/permission/consent-permission/${consentPermissionId}`,
      { signal: abortControllerSignal }
    );

    return response.data;
  },
  getSignedConsents: async (queryParams) => {
    if (!serviceUtil.isObject(queryParams))
      return [];

    queryParams = serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);

    const response = await http.backendInstance.get("/api/permission/signed-permission-list", {
      params: queryParams,
    });

    return response.data;
  },
};
export default permissionService;
