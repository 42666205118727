import { Outlet } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import { Footer } from "../footer/footer";
import { tokenExp } from "./utility";
import moment from "moment";
import React from "react";
import useToken from "../../hooks/useToken";
import Header from "../header/Header";
import LogoutDialog from "./LogoutDialog";

const Fallback = () => {
  return (
    <div
      style={{
        minHeight: "1000px",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress disableShrink />
    </div>
  );
};

export const Layout = () => {
  const { token } = useToken();

  const [tokenExpiration, setTokenExp] = React.useState(
    moment().add(30, "m").toDate()
  );
  React.useEffect(() => {
    if (token !== "") {
      if (sessionStorage.getItem("time") === null) {
        setTokenExp(tokenExp(token));
        sessionStorage.setItem("time", tokenExp(token));
      }
    }
  }, []);

  React.useEffect(() => {
    if (sessionStorage.getItem("time") !== null)
      setTokenExp(sessionStorage.getItem("time"));
  }, [token, tokenExpiration]);

  return (
    <>
    <Header />
      <Grid
        container
        direction="column"
        rowSpacing={1}
      >
        <Grid
          item
          justify="center"
          alignItems="center"
          sx={{ minHeight: "720px", width: "100%" }}
        >
          <React.Suspense fallback={<Fallback />}>
            <Outlet />
          </React.Suspense>
        </Grid>
        <Grid item>
          <LogoutDialog />
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};
