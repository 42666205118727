import http from "../api/http";

const messageService = {
  createMessage: async (formData) => {
    const response = await http.backendInstance.post("/api/message", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  },
  getMessageByReceiversUsersId: async (messageReceiversUsersId, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      `/api/message/${messageReceiversUsersId}`,
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },
  getMessagesByStatus: async (queryParams, abortControllerSignal = null) => {
    const response = await http.backendInstance.get("/api/message",
      {
        params: { ...queryParams },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },
  modifyMessageArchiveStatus: async (messagesToModify, abortControllerSignal = null) => {
    const response = await http.backendInstance.put(
      "/api/message/archive-status",
      { ...messagesToModify },
      { signal: abortControllerSignal }
    );

    return response.data;
  },
  getUnreadMessageCount: async (abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/message/unread-message-count",
      {
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },
  getUnreadMessages: async (queryParams, abortControllerSignal = null) => {
    const response = await http.backendInstance.get("/api/message/unread-messages",
      {
        params: { ...queryParams },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },
  markMessageAsRead: async (messageId, abortControllerSignal = null) => {
    const response = await http.backendInstance.put(
      `/api/message/mark-as-read/${messageId}`,
      {},
      { signal: abortControllerSignal }
    );

    return response.data;
  },
};

export default messageService;
