import http from "../api/http";

const accountService = {
  shouldUserCreateParticipantProfile: async (abortControllerSignal = null) => {
    const result = await http.authInstance.get(
      "/account/should-create-participant-profile",
      {
        signal: abortControllerSignal
      }
    );

    return result.data;
  },
};

export default accountService;
