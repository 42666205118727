import { useState } from "react";
import { LocalStorageKey } from "../enums";
import { jwtDecode } from "jwt-decode";
import "core-js/stable/atob";

// stores access token is local storage
const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(getAccessToken());

  function getAccessToken() {
    return localStorage.getItem(LocalStorageKey.AccessToken);
  };

  function saveAccessToken(accessTokenToSet) {
    localStorage.setItem(LocalStorageKey.AccessToken, accessTokenToSet);
    setAccessToken(accessTokenToSet);
  };

  function isAccessTokenExpired() {
    if (!accessToken?.length)
      return true;

    const decodedToken = jwtDecode(accessToken);

    return decodedToken?.exp <= Math.floor(Date.now() / 1000);
  }

  function isAuthenticated() {
    if (!accessToken?.length)
      return false;

    return !isAccessTokenExpired();
  }

  return {
    setAccessToken: saveAccessToken,
    accessToken,
    isAccessTokenExpired,
    isAuthenticated
  }
};

export default useAccessToken;
