import { DataGrid } from "@mui/x-data-grid";
import { TitleForm, CustomBreadCrumbs } from "../../MUI_components";
import AddButton from "../../MUI_components/AddButton";
import Container from "@mui/material/Container";
import ExportButton from "../../MUI_components/ExportButton";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import useToken from "../../hooks/useToken";
import RIGHTS from "../../rights_enum";
import { NoRowsOverlay } from "../../components/data-grid/NoRowsOverlay";
import { DataGridPaginationProps } from "../../components/data-grid/DataGridPaginationProps";
import { useContext, useState } from "react";
import SelectedRowContext, { SelectedRowProvider } from "../../components/SelectedRowProvider";
import { FormProvider, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import ConsentListSearchFields from "../../components/consent/ConsentListSearchFields";
import { useDispatch } from "react-redux";
import permissionService from "../../services/permission-service";
import timeUtil from "../../utils/time-util";
import CONSTANTS from "../../constants";
import { ConsentResponsesTypeEnum, ConsentResponseTypeMapLV } from "../../enums";

const links = [{ text: "Piekrišanas elementu saraksts", to: "/permissions" }];

const defaultFormValues = {
  code: "",
  titleLv: "",
  responses: "",
  dateCreatedFrom: null,
  dateCreatedTo: null,
  dateUpdatedFrom: null,
  dateUpdatedTo: null,
};

const columns = [
  {
    field: "code",
    headerName: "Kods",
    width: 200,
    editable: false,
  },
  {
    field: "titleLv",
    headerName: "Nosaukums",
    width: 300,
    editable: false,
  },
  {
    field: "responses",
    headerName: "Atbildes varianti",
    width: 300,
    editable: false,
    valueGetter: (params) => {
      const row = params.row;
      const withResponses = row.withResponses;
      const multipleResponsesAllowed = row.multipleResponsesAllowed;

      if (withResponses && multipleResponsesAllowed) {
        return ConsentResponseTypeMapLV.get(ConsentResponsesTypeEnum.MultipleOptions);
      } else if (withResponses && !multipleResponsesAllowed) {
        return ConsentResponseTypeMapLV.get(ConsentResponsesTypeEnum.OneResponse);
      } else {
        return ConsentResponseTypeMapLV.get(ConsentResponsesTypeEnum.NoOption);
      }
    },
  },
  {
    field: "dateCreated",
    headerName: "Izveidots",
    width: 200,
    editable: false,
    valueFormatter: ({ value }) => timeUtil.date(value),
  },
  {
    field: "dateUpdated",
    headerName: "Labots",
    width: 200,
    editable: false,
    valueFormatter: ({ value }) => timeUtil.date(value),
  },
];

const PermissionListView = () => {
  const navigate = useNavigate();
  const [searchParamsState, setSearchParamsState] = useState({});
  const [gridData, setGridData] = useState(CONSTANTS.GRID_TEMPLATE);

  const { setSelectedRow } = useContext(SelectedRowContext);

  const handleEditResearch = (params) => {
    const row = params.row;
    setSelectedRow(row);
    navigate(`/permissions/${row.id}/edit`);
  };

  const handleNavigateAddResearch = () => {
    navigate("/permissions/create");
  };

  const [show, setShow] = React.useState(false);

  const { rights } = useToken();

  const [gridState, setGridState] = React.useState({
    ...gridData,
    searchParams: defaultFormValues,
  });

  const form = useForm({ defaultValues: defaultFormValues });

  const [initialRows, setInitialRows] = React.useState([]);

  const dispatch = useDispatch();

  const resetForm = () => {
    form.reset();
    setGridState(prevState => ({ ...gridData, searchParams: defaultFormValues }));
    setShow(false);
    fetchData();
  };

  const fetchData = async () => {
    setGridState(prev => ({ ...prev, loading: true }));
    const searchParams = new URLSearchParams();

    searchParams.append("pageNumber", gridState.page);
    searchParams.append("pageSize", gridState.pageSize);

    Object.keys(gridState.searchParams).forEach(key => {
      let value = gridState.searchParams[key];
      if (value) {
        value = toISOStringIfDate(value);
        searchParams.append(key, value);
      }
    });

    try {
      const permissions = await permissionService.getPermissions(searchParams);
      setGridState((prevState) => ({
        ...prevState,
        rows: permissions.items,
        totalRows: permissions.totalCount,
        loading: false
      }));

    } catch (error) {
      console.error(error?.message);
      setGridState(prev => ({ ...prev, loading: false }));
    }
  };

  function toISOStringIfDate(value) {
    if (value && typeof value._isAMomentObject !== 'undefined' && value._isAMomentObject) {
      return value.toISOString();
    }
    return value;
  }

  const handleShowAll = () => {
    setShow(!show);
  };

  const handleSubmitForm = async (data, e) => {
    setGridState(prevState => ({
      ...prevState,
      searchParams: data,
      page: 0
    }));
  };

  React.useEffect(() => {
    async function reFetch() {
      await fetchData();
    }

    reFetch();
  }, [gridState.page, gridState.pageSize, gridState.searchParams]);

  return (
    <>
      <Container maxWidth="xl">
        <CustomBreadCrumbs links={links} />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmitForm)}>
            <Box
              sx={{
                border: "1px solid grey",
                borderRadius: 1,
                position: "relative",
                padding: 2,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "-10px",
                  left: "10px",
                  background: "#ffffff",
                  padding: "0 10px",
                }}
              >
                Filtrēt ierakstus
              </Typography>
              <ConsentListSearchFields resetFiltersAndRows={resetForm} />
            </Box>
          </form>
        </FormProvider>
        <TitleForm
          paddingTop="1.5rem"
          isTitleBigger={true}
          titleCode={"FRM.PERM.SR"}
          title={"Piekrišanas elementu saraksts"}
        />
        <Grid
          container
          display="flex"
          margin="1rem 0"
          justifyContent={"space-between"}
        >
          <Grid item xs={12} lg={2} xl={2} mt={1}>
            {rights.includes(RIGHTS.RES_CREATE) && (
              <AddButton
                variant="contained"
                fullWidth={true}
                onClick={handleNavigateAddResearch}
                label="Pievienot jaunu"
              />
            )}
          </Grid>
          <Grid item xs={12} lg={1} xl={2} mt={1} textAlign="end">
            {rights.includes(RIGHTS.RES_EXPORT) && (
              <ExportButton
                variant="contained"
                fullWidth={true}
                label="Eksportēt"
              />
            )}
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", overflowX: "auto", mb: 2 }}>
          <DataGrid
            autoHeight
            rows={gridState.rows}
            columns={columns}
            localeText={{ noRowsLabel: "Nav rindu" }}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => setGridState(prev => ({ ...prev, page: newPage }))}
            onPageSizeChange={(newPageSize) => setGridState(prev => ({ ...prev, pageSize: newPageSize }))}
            onCellClick={handleEditResearch}
            page={gridState.page}
            pageSize={gridState.pageSize}
            rowCount={gridState.totalRows}
            loading={gridState.loading}
            rowsPerPageOptions={gridState.rowsPerPageOptions}
            slots={{
              NoRowsOverlay: NoRowsOverlay,
              NoResultsOverlay: NoRowsOverlay,
            }}
            componentsProps={{ pagination: { ...DataGridPaginationProps() } }}
          />
        </Box>
      </Container>
    </>
  );
};

export default PermissionListView;
