import UploadOutlined from "@mui/icons-material/UploadOutlined";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const ExportButton = ({ label = "Export" }) => {
  return (
    <Button color="primary" variant="outlined" startIcon={<UploadOutlined />} sx={{ height: "40px" }}>
      {label}
    </Button>
  );
};

ExportButton.propTypes = {
  label: PropTypes.string,
};

export default ExportButton;
