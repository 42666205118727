import http from "../api/http";

const classifierService = {
  getClassifierList: async (classifierTableName, queryParams, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/classifier/get-clasificator-list",
      {
        params: { classifierTableName, ...queryParams },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },
  getClassifierById: async (classifierTableName, classifierId, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/classifier/specific-classificator-from-table",
      {
        params: { classifierTableName, classifierId },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },

  isClassifierCodeValid: async (
    classifierTableName,
    classifierCode,
    abortControllerSignal = null
  ) => {
    const response = await http.backendInstance.get(
      "/api/classifier/is-valid-classifier-code",
      {
        params: { classifierTableName, classifierCode },
        signal: abortControllerSignal,
      }
    );
    return response.data;
  },

  getClassifierTableSelectListItems: async (classifierTableName,
    abortControllerSignal = null
  ) => {
    const response = await http.backendInstance.get(
      "/api/classifier/cl-table-select-list-items",
      {
        params: { classifierTableName },
        signal: abortControllerSignal,
      }
    );
    return response.data;
  },
};

export default classifierService;
