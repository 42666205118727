import http from "../api/http";
import serviceUtil from "../utils/service-util";

const researchService = {
  getResearchById: async (researchId) => {
    const response = await http.backendInstance.get(
      `/api/research/${researchId}`
    );

    return response.data;
  },

  getResearchParticipants: async (researchId, queryParams) => {
    if (!serviceUtil.isObject(queryParams)) return [];

    const response = await http.backendInstance.get(
      "/api/research/participants",
      { params: { researchDraftId: researchId, ...queryParams } }
    );

    return response.data;
  },
  addParticipantsToResearchDraft: async (personCodes, researchDraftId) => {
    const response = await http.backendInstance.post(
      "/api/research/participants",
      {
        personCodes,
        researchDraftId,
      }
    );
    return response.data;
  },
  getResearchDrafts: async (queryParams) => {
    if (!serviceUtil.isObject(queryParams)) return [];

    queryParams =
      serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);

    const response = await http.backendInstance.get("/api/research/list", {
      params: queryParams,
    });

    return response.data;
  },

  addParticipantsFromRelatedResearch: async (
    researchDraftId,
    relatedDraftId
  ) => {
    const response = await http.backendInstance.post(
      "/api/research/allowedParticipants",
      { researchDraftId, relatedDraftId }
    );

    return response.data;
  },

  removeParticipantsFromResearch: async (
    researchDraftId,
    researchParticipantIds
  ) => {
    const response = await http.backendInstance.delete(
      "/api/research/participants",
      { data: { researchDraftId, researchParticipantIds } }
    );

    return response.data;
  },

  getInvolvedResearchers: async (researchDraftId) => {
    const response = await http.backendInstance.get(
      `/api/research/involved-researchers/${researchDraftId}`
    );

    return response.data;
  },
  isParticipantAlreadyBeenAssignedToResearch: async (
    researchDraftId,
    participantPersonalCodeToCheck
  ) => {
    const response = await http.backendInstance.get(
      "/api/research/is-participant-already-assigned-to-research",
      {
        params: {
          researchDraftId,
          researchParticipantPersonalCode: participantPersonalCodeToCheck,
        },
      }
    );

    return response.data;
  },

  getPublicResearchById: async (researchPublicationId) => {
    const response = await http.backendInstance.get(
      `/api/public/${researchPublicationId}`
    );

    return response.data;
  },
  getResearchConsents: async (researchId, queryParams, abortControllerSignal = null) => {
    if (!serviceUtil.isObject(queryParams)) return [];

    queryParams =
      serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);
    const response = await http.backendInstance.get(
      "/api/research/research-consents",
      {
        params: { ...queryParams, researchId },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },

  getConsentsListItems: async (researchId) => {
    const response = await http.backendInstance.get(
      `/api/research/consent-select-list-items/${researchId}`
    );

    return response.data;
  },

  publishResearch: async (researchId) => {
    const response = await http.backendInstance.post(
      `/api/research/publish/${researchId}`
    );
    return response.data;
  },

  sendForPublication: async (researchId) => {
    const response = await http.backendInstance.post(
      `/api/research/send-for-publication/${researchId}`
    );
    return response.data;
  },

  getFromSearch: async (searchQuery) => {
    const response = await http.backendInstance.get("/api/research/search", {
      params: {
        ...searchQuery,
      },
    });

    return response.data;
  },

  getResearchParticipantList: async (researchId, queryParams) => {
    const response = await http.backendInstance.get(
      "/api/research/research-participant-list",
      {
        params: {
          ...queryParams,
          researchId,
        },
      }
    );

    return response.data;
  },

  deleteResearchParticipantsDataFromResearch: async (researchId) => {
    const response = await http.backendInstance.delete(
      `/api/research/participantData/${researchId}`
    );

    return response.data;
  },

  deleteResearch: async (researchId) => {
    const response = await http.backendInstance.delete(
      `/api/research/${researchId}`
    );

    return response.data;
  },

  canDeleteResearch: async (researchId) => {
    const response = await http.backendInstance.get(
      "/api/research/can-delete-research",
      {
        params: {
          researchId,
        },
      }
    );

    return response.data;
  },

  canPublishResearch: async (researchId) => {
    const response = await http.backendInstance.get(
      "/api/research/can-publish-research",
      {
        params: {
          researchId,
        },
      }
    );

    return response.data;
  },

  addParticipantsToResearchByFile: async (
    fileWithParticipantsPersonCodes,
    researchId
  ) => {
    const formData = new FormData();
    formData.append("personCodesFile", fileWithParticipantsPersonCodes);
    formData.append("researchId", researchId);

    const response = await http.backendInstance.post(
      "/api/research/import-participants",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  },
  getUserResearches: async (queryParams, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/research/user-researches",
      {
        params: {
          ...queryParams,
        },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },
  getInvolvedResearchersDropdown: async (abortControllerSignal = null) => {
    const response = await http.backendInstance.get("/api/research/involvedResearchers",
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },
  saveFourthStage: async (fourthStageData) => {
    const response = await http.backendInstance.put("/api/research/fourthstage", fourthStageData);

    return response.data;
  }
};

export default researchService;
