import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { useTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SelectedRowProvider } from './components/SelectedRowProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Router from './routes/routes'


const App = () => {
  const { customTheme } = useTheme();

  return (
    <SelectedRowProvider>
      <ThemeProvider theme={customTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <Router/>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </SelectedRowProvider>
  );
};

export default App;