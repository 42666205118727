import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import "./dropdown.scss";

const Hoverable = ({ pathName, children, includedInPaths = [], isElementSelectedCallback }) => {
  const location = useLocation();

  function isElementSelected() {
    return hasSamePathName() || isPathNameIncluded();
  }

  function hasSamePathName() {
    return location.pathname === pathName;
  }

  function isPathNameIncluded() {
    return includedInPaths.some(pathName => pathName === location.pathname);
  }
  useEffect(() => {
    if (!isElementSelectedCallback)
      return;

    const isSelected = isElementSelected();
    isElementSelectedCallback(isSelected);

  }, [isElementSelected()]);
  return (
    <Box
      sx={{ height: "2rem" }}
      className={
        isElementSelected()
          ? "dropdown-link dropdown-link--active"
          : "dropdown-link"
      }
    >
      {children}
    </Box>
  );
};

export default Hoverable;
