import http from "../api/http";

const translationService = {
  updateTranslations: async (translationsToUpdateModelView) => {
    const response = await http.backendInstance.put(
      "/api/translation",
      translationsToUpdateModelView
    );

    return response.data;
  },

  updateTranslationsByFile: async (formData) => {
    const response = await http.backendInstance.post(
      "/api/translation/translation-file",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  },

  getFieldTranslations: async (abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/translation/fields",
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },

  getElementTranslations: async (abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/translation/elements",
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },

  getNotificationTranslations: async (abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/translation/notifications",
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },
};
export default translationService;
