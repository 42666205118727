import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Add from "@mui/icons-material/Add";
import React from "react";
import moment from "moment";
import { CustomBreadCrumbs, TitleForm } from "../../../MUI_components";
import { MoreInformationCmsDraggableGroups } from "../../../components/cms/moreInformation/DraggableGroups";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const links = [
  { to: "/cms/more-information", text: "Satura pārvaldība - Vairāk informācijas" }
];

export const MoreInformationCmsDraggableListView = React.memo(() => {

  const updateDate = useSelector(state => state.Cms.moreInformationUpdateDate);


  return (
    <>
      <Grid item xs={12}>
        <CustomBreadCrumbs links={links} />
      </Grid>
      <Grid item xs={12}>
        <TitleForm
          title={"Vairāk informācijas"}
          titleCode={"FRM.INFO.SR"}
          dateChanged={`${moment(updateDate).format("DD.MM.YYYY. HH:mm:ss")}`}
        />
      </Grid>
      <MoreInformationCmsDraggableGroups />
      <Grid item xs={12} my={2}>
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={"/cms/more-information/groups/add"}
          startIcon={<Add />}
        >
          Pievienot grupu
        </Button>
      </Grid>
    </>
  );
});
