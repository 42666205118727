import {
  setUserRoles,
  unloadAuthData,
  setSingleUserRole,
  setAccessToken,
  setAccessRights,
  setTokenLifeTime,
  setAuthenticated,
  setAssignedGroupsCode,
} from "../../redux/actions/auth";
import http from "../http";
import { SetStorageAuthenticated } from "../utility";
import baseUrl from "../http";
import { LocalStorageKey } from "../../enums";

export const Logout = () => async (dispatch) => {
  try {
    await baseUrl.authInstance("/api/auth/logout");
  } catch (error) {
    console.log(error);
  } finally {
    sessionStorage.clear();
    localStorage.clear();
    setBearerHeader("");
    dispatch(unloadAuthData());
  }
};

export const ClearStorage = () => async (dispatch) => {
  sessionStorage.clear();
  setBearerHeader("");
  dispatch(unloadAuthData());
};

export const GetRoles = async () => {
  return baseUrl.authInstance.get("/api/auth/roles/");
};

export const RefreshPassword = (navigate, passwords) => async (dispatch) => {
  const body = JSON.stringify(passwords);

  http.authInstance.post("/api/auth/refreshPassword", body).then((x) => {
    //choose role request
    GetRoles().then((y) => {
      const roles = y.data;
      dispatch(setUserRoles(roles));
      if (roles.length > 1) {
        navigate("/role", { replace: true });
        return;
      }
      dispatch(setSingleUserRole(roles[0]));
      dispatch(GetAccessToken(navigate));
    });
  });
};

export const GetAccessToken = (navigate) => async (dispatch, getState) => {
  const code = getState().Auth.role.code;
  const body = JSON.stringify({ code });

  try {
    const result = await baseUrl.authInstance.post("/api/auth/accessToken", body);
    dispatch(setAccessToken(result.data.accessToken));
    setBearerHeader(result.data.accessToken);
    dispatch(setAccessRights(result.data.rights));
    dispatch(setTokenLifeTime(result.data.tokenLifeTime));
    SetStorageAuthenticated(true);
    navigate("/dashboard", { replace: true });

  } catch (error) {
    console.error(error);
    navigate("/", { replace: true });
  }
};

export const setBearerHeader = (token) => {
  
  for (let instance in baseUrl)
    baseUrl[instance].defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
};

export const RefreshAccessToken = () => async (dispatch, getState) => {
  try {
    const refresh = await baseUrl.authInstance.post("/api/auth/refreshToken", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });

    const isAuthenticated = getState().Auth.isAuthenticated;

    const { rights, accessToken, role, tokenLifeTime, assignedGroupCodes } = refresh.data;

    if (!isAuthenticated)
      dispatch(setAuthenticated(true));

    dispatch(setAccessToken(accessToken));
    dispatch(setAccessRights(rights));
    dispatch(setSingleUserRole(role));
    dispatch(setTokenLifeTime(tokenLifeTime));
    dispatch(setAssignedGroupsCode(assignedGroupCodes));
    localStorage.setItem(LocalStorageKey.AccessToken, accessToken);
  } catch (error) {
    console.error(error);
  }
};
