import http from "../api/http";

const userActivityService = {
  getUserActivities: async (queryParams, abortControllerSignal = null) => {
    const response = await http.auditInstance.get(
      "/api/useractivity/list?",
      {
        params: queryParams,
        signal: abortControllerSignal
      }
    );
    return response.data;
  }
};

export default userActivityService;