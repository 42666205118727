import http from "../api/http";

const termsOfUseService = {
  acceptTermsOfUse: async (userId, termsOfUseId) => {
    const response = await http.backendInstance.post(
      "/api/terms-of-use/accept-terms-of-use",
      { userId, termsOfUseId }
    );

    return response.data;
  },

  getCurrentUserActualTermOfUseId: async (abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/terms-of-use/actual-term-of-use-id",
      {
        signal: abortControllerSignal
      }
    );

    return response.data;
  },

  isUserAlreadyAcceptedTermOfUse: async (userId, termsOfUseId, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/terms-of-use/is-user-already-accepted-term-of-use",
      {
        params: { userId, termsOfUseId },
        signal: abortControllerSignal,
      }
    );

    return response.data;
  },

  getTermsOfUseById: async (termsOfUseId, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      `/api/terms-of-use/${termsOfUseId}`,
      { signal: abortControllerSignal }
    );

    return response.data;
  },

  createTermsOfUse: async (termsOfUseToCreateViewModel) => {
    const response = await http.backendInstance.post(
      "/api/terms-of-use",
      { ...termsOfUseToCreateViewModel }
    );

    return response.data;
  },

  getActualTermOfUseIdByRole: async (roleCode) => {
    const response = await http.backendInstance.get(
      "/api/terms-of-use/actual-term-of-use",
      {
        params: { roleCode }
      }
    );

    return response.data;
  },

  getTermsOfUseTableRecords: async (queryParams, abortControllerSignal = null) => {
    const response = await http.backendInstance.get(
      "/api/terms-of-use/terms-of-use-list",
      {
        params: { ...queryParams },
        signal: abortControllerSignal
      }
    );

    return response.data;
  },
};

export default termsOfUseService;
