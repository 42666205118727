import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Edit from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu';
import useRightTranslation from "../../../hooks/useRightTranslation";
import Fade from "@mui/material/Fade";
import DraggableGroupItems from "../../../components/cms/moreInformation/DraggableGroupItems";
import { Link } from "react-router-dom";

//One group contains info
export const MoreInformationCmsGroup = React.forwardRef(
  ({ group, dragProps, draggableProps, handleDialogOpen: handleGroupDialogOpen }, ref) => {

    const { GetRightTranslation } = useRightTranslation();

    const [isExpanded, setIsExpanded] = React.useState(false);

    const handleExpand = () => {
      setIsExpanded((prevState) => !prevState);
    };

    const { id, groupTitleLv, groupTitleEn, groupTitleRu } = group || {};

    return (
      <>
          <Fade in={true} timeout={500}>
              <Grid container my={1} {...dragProps} {...draggableProps} ref={ref}>
                  <Grid
                      container
                      boxShadow={"0px 2px 15px rgba(51, 51, 51, 0.15)"}
                      borderRadius={"4px"}
                      dispaly="flex"
                      padding={2}
                      alignItems={"center"}
                      component={Box}
                  >
                      <Grid display={'flex'} gap="1rem" onClick={handleExpand} flex={1}>
                          <MenuIcon color={'primary'}/>
                          <Typography>{GetRightTranslation(groupTitleLv, groupTitleEn, groupTitleRu)}</Typography>
                      </Grid>
                      <Grid>
                          <Button
                              color="primary"
                              startIcon={<Edit/>}
                              component={Link}
                              to={`/cms/more-information/groups/${id}/edit`}
                          >
                              <Typography color={(theme) => theme.palette.text.link_color}>
                                  Rediģēt
                              </Typography>
                          </Button>
                          <Button
                              variant="text"
                              startIcon={<DeleteOutlineIcon />}
                              onClick={() => handleGroupDialogOpen(group.id)}
                          >
                              <Typography color={(theme) => theme.palette.text.link_color}>
                                  Dzēst
                              </Typography>
                          </Button>
                      </Grid>
                  </Grid>
              </Grid>
          </Fade>
          {isExpanded ? (
              <>
                  <DraggableGroupItems groupId={group.id} />
                  <Grid item xs={12} px={3} my={2}>
                      <Button
                          variant="outlined"
                          color="primary"
                          component={Link}
                          to={`/cms/more-information/groups/${id}/item/add`}
                      >
                          Pievienot ierakstu
                      </Button>
                  </Grid>
              </>
          ) : null}
      </>
    );
  }
);
