// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-link {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  position: relative;
  cursor: pointer;
}
.dropdown-link::after {
  content: "";
  width: 0;
  height: 2px;
  background-color: rgb(23, 84, 134);
  position: absolute;
  bottom: 0;
  left: 0;
}
.dropdown-link::after:hover {
  width: 100%;
}
.dropdown-link--active {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown-link--active::after {
  content: "";
  transition: width 0.2s linear;
  width: 100%;
  height: 2px;
  background-color: rgb(23, 84, 134);
  position: absolute;
  bottom: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/dropdownMenu/dropdown.scss","webpack://./src/style/App.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;ACCF;ADCE;EACE,WAAA;EACA,QAAA;EACA,WAAA;EACA,kCAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;ACCJ;ADCI;EACE,WAAA;ACCN;ADGE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;ACDJ;ADGI;EACE,WAAA;EACA,6BAAA;EACA,WAAA;EACA,WAAA;EACA,kCAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;ACDN","sourcesContent":[".dropdown-link {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  transition: all 0.2s linear;\n  position: relative;\n  cursor: pointer;\n\n  &::after {\n    content: \"\";\n    width: 0;\n    height: 2px;\n    background-color: rgba(23, 84, 134, 1);\n    position: absolute;\n    bottom: 0;\n    left: 0;\n\n    &:hover {\n      width: 100%;\n    }\n  }\n\n  &--active {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    position: relative;\n\n    &::after {\n      content: \"\";\n      transition: width 0.2s linear;\n      width: 100%;\n      height: 2px;\n      background-color: rgba(23, 84, 134, 1);\n      position: absolute;\n      bottom: 0;\n      left: 0;\n    }\n  }\n}\n",".dropdown-link {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  transition: all 0.2s linear;\n  position: relative;\n  cursor: pointer;\n}\n.dropdown-link::after {\n  content: \"\";\n  width: 0;\n  height: 2px;\n  background-color: rgb(23, 84, 134);\n  position: absolute;\n  bottom: 0;\n  left: 0;\n}\n.dropdown-link::after:hover {\n  width: 100%;\n}\n.dropdown-link--active {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n.dropdown-link--active::after {\n  content: \"\";\n  transition: width 0.2s linear;\n  width: 100%;\n  height: 2px;\n  background-color: rgb(23, 84, 134);\n  position: absolute;\n  bottom: 0;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
