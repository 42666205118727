import Button from "@mui/material/Button";
import React from "react";
import Grid from "@mui/material/Grid";
import {
  ResponseSelect,
  CustomInput,
  CustomDatePicker,
} from "../../MUI_components";
import Typography from "@mui/material/Typography";

const ConsentListSearchFields = ({ resetFiltersAndRows }) => {
  const handleToggleSearch = () => {
    resetFiltersAndRows();
  };

  return (
    <Grid container direction="column" spacing={2}>
      {/* First row */}
      <Grid item container spacing={2}>
        <Grid item xs={12} md={3}>
          <CustomInput fullWidth name={"code"} label={"Kods"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomInput size="small" name={"titleLv"} label={"Nosaukums"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ResponseSelect name={"responses"} defaultValue={""} />
        </Grid>
        <Grid item xs={12} md={3} pl={"2rem"} display={"flex"} gap={"1rem"} flexDirection={{sx:"column", md:"row"}}>
          <Button size={"small"} variant={"contained"} color={"primary"} type={"submit"}>
            Atlasīt
          </Button>
          <Button
            size={"small"}
            onClick={handleToggleSearch}
            variant={"contained"}
            color={"error"}
            type={"button"}
          >
            Notīrīt
          </Button>
        </Grid>
      </Grid>

      {/* Second row */}
      <Grid item container spacing={2}>
        <Grid item xs={12} md={3} container direction="column" spacing={1}>
          <Grid item>
            <Typography>Izveidots</Typography>
          </Grid>
          <Grid item container direction="row" spacing={1}>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                defaultValue={""}
                fullWidth
                label={"No"}
                name={"dateCreatedFrom"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                defaultValue={""}
                fullWidth
                label={"Līdz"}
                name={"dateCreatedTo"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} container direction="column" spacing={1}>
          <Grid item>
            <Typography>Labots</Typography>
          </Grid>
          <Grid item container direction="row" spacing={1}>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                defaultValue={""}
                fullWidth
                label={"No"}
                name={"dateUpdatedFrom"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                defaultValue={""}
                fullWidth
                label={"Līdz"}
                name={"dateUpdatedTo"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsentListSearchFields;
