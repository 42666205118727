import Grid from "@mui/material/Grid";
import * as PropTypes from "prop-types";
import http from "../../../api/http";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteGroupItemDialog from "./DeleteGroupItemDialog";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MoreInformationCmsItem } from "../../../pages/cms/moreInformation/MoreInformationCmsItem";
import React, { useEffect } from "react";
import { reorder } from "../../../pages/cms/utilities";
import { setMoreInformationGroupsUpdateDate } from "../../../redux/actions/cms";
import { useDispatch } from "react-redux";

const DraggableGroupItems = ({ groupId }) => {

    const [items, setItems] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDeleteGroupItemDialogOpen, setIsDeleteGroupItemDialogOpen] = React.useState(false);
    const [itemIdToDelete, setItemIdToDelete] = React.useState(null);
    const dispatch = useDispatch();

    const fetchCmsGroupItems = async () => {
        try {
            const { data } = await http.backendInstance.get(`/api/public/moreinfoitems/${groupId}`);
            setItems(data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const deleteCmsGroupItem = async () => {
        try {
            await http.backendInstance.delete(`/api/cms/moreinfoitem/${itemIdToDelete}`);
            dispatch(setMoreInformationGroupsUpdateDate(Date.now()));
            setIsDeleteGroupItemDialogOpen(false);
            setItems(items.filter(item => item.id !== itemIdToDelete));
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloseDeleteItemDialog = () => {
        setIsDeleteGroupItemDialogOpen(false);
        setItemIdToDelete(null);
    }

    const handleItemDialogOpen = (id) => {
        setIsDeleteGroupItemDialogOpen(true);
        setItemIdToDelete(id);
    };
    const onDragEnd = async ({ destination, source }) => {
        if (!destination || destination.index === source.index) return;
        //change order of group items
        const newItems = reorder(items, source.index, destination.index);
        setItems(newItems);
        await saveGroupItemsOrder(source.index + 1, destination.index + 1);
    };

    const saveGroupItemsOrder = async (source, destination) => {
        try {
            await http.backendInstance.put("/api/cms/ordermoreinfoitem", {
                groupId: groupId,
                source,
                destination
            });
            dispatch(setMoreInformationGroupsUpdateDate(new Date()));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCmsGroupItems();
    }, []);

    if (isLoading) {
        return (
            <>
                <Grid container>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <>
            <DeleteGroupItemDialog
                open={isDeleteGroupItemDialogOpen}
                onClose={handleCloseDeleteItemDialog}
                onClick={deleteCmsGroupItem}
            />
            <Grid item xs={12} px={3}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-list">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {items.map((item, index) => (
                                    <Draggable draggableId={String(item.id)} index={index} key={item.id}>
                                        {(provided, snapshot) => (
                                            <MoreInformationCmsItem
                                                draggableProps={{ ...provided.draggableProps }}
                                                dragProps={{ ...provided.dragHandleProps }}
                                                ref={provided.innerRef}
                                                isDragging={snapshot.isDragging}
                                                item={item}
                                                handleItemDialogOpen={handleItemDialogOpen}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </>
    );
}

DraggableGroupItems.propTypes = {
    onDragEnd: PropTypes.func,
    prop1: PropTypes.func
};

export default DraggableGroupItems;
