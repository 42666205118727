import { useState } from "react";
import { LocalStorageKey } from "../enums";
import { jwtDecode } from "jwt-decode";
import "core-js/stable/atob";

const useAccessToken = () => {
  const [rights] = useState(getRights());
  const accessToken = getAccessToken();

  function getRights() {
    const accessToken = getAccessToken();
    if (!accessToken?.length)
      return [];

    const decodedToken = jwtDecode(accessToken);

    return decodedToken?.Right ?? [];
  }

  function getAccessToken() {
    return localStorage.getItem(LocalStorageKey.AccessToken);
  };

  function saveAccessToken(accessTokenToSet) {
    localStorage.setItem(LocalStorageKey.AccessToken, accessTokenToSet);
  };

  function isAccessTokenExpired() {
    const accessToken = getAccessToken();
    if (!accessToken?.length)
      return true;

    const decodedToken = jwtDecode(accessToken);
    return decodedToken?.exp <= Math.floor(Date.now() / 1000);
  }

  function isAuthenticated() {
    const accessToken = getAccessToken();
    if (!accessToken?.length)
      return false;

    return !isAccessTokenExpired();
  }

  return {
    setAccessToken: saveAccessToken,
    accessToken,
    isAccessTokenExpired,
    isAuthenticated,
    rights
  }
};

export default useAccessToken;
