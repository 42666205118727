import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import React from "react";
import { useLocation } from "react-router-dom";

const DropdownMenu = ({
  menuName,
  ariaControls,
  ariaLabelledBy,
  dropdownItemsPaths = [],
  id,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const location = useLocation();
  const selectedTabColor = "rgba(23, 84, 134, 1)";

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function isOneOfTheElementsOfDropDownSelected() {

    return dropdownItemsPaths.some(path => path === location.pathname);
  }

  return (
    <div>

          <Link
            aria-controls={ariaControls}
            aria-expanded={open ? true : false}
            id={ariaLabelledBy}
            onClick={handleClick}
          >
          <Typography
            align="center"
            fontFamily="Segoe UI"
            sx={{
              textDecoration: "none",
              color: isOneOfTheElementsOfDropDownSelected() ? selectedTabColor : "black",
              "&:hover": {color: selectedTabColor},
              textTransform: "uppercase",
              align: "center",
              height: "1.5rem"
            }}
          >
            {menuName}
            </Typography>
          </Link>

      <Menu
        id={ariaControls}
        aria-labelledby={ariaLabelledBy}
        anchorEl={anchorEl}
        sx={{ zIndex: 100000001 }}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {children({ handleClose })}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
