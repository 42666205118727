import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import "./index.css";
import App from "./App";
import { Buffer } from "buffer";


window.Buffer = Buffer;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore}>
    <App />
  </Provider>
);
