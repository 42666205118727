const CONSTANTS = Object.freeze({
  DATE_FORMAT: "DD.MM.YYYY",
  DATE_ISO_FORMAT: "YYYY-MM-DD",
  TIME_FORMAT: "HH:mm",
  SECONDS_FORMAT: "ss",
  MIN_DATE: "1900-01-01T00:00:00.000Z",
  DEFAULT_LOCALE: "lv",
  GRID: {
    ROWS_PER_PAGE_OPTIONS: [5, 10, 15],
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_PAGE: 0,
  },
  GRID_TEMPLATE: {
    loading: true,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 10,
    page: 0,
  },
  TINYMCE_REGEX: /(<([^>]+)>)/gi,
  EMAIL_REGEX: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  DEFAULT_TEXT_INPUT_MAX_LENGTH: 500,
  PAGINATION: {
    PAGE_SIZE: 5,
    PAGE: 0,
  },
  MAX_FILE_SIZE_IN_BYTES: 54525952, // or 52 MB
  SPREADSHEET_FILE_FORMATS: ["xls", "xlsx"],
  SYSTEM_NOTIFICATION_COLORS: {
    INFORMATIONAL: "#EBF6FF",
    CAUTION: "#FFF6D6"
  },
  TIMESTAMP_FORMAT: "YYYY-MM-DD-HH-mm-ss"
});

export default CONSTANTS;
