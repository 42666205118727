import React from "react";
import Grid from "@mui/material/Grid";
import { TextInputField } from "../../../MUI_components";
import { TextEditorTinyMce } from "../../richTextEditor/TextEditor";

export const FAQCmsGroupItemEditForm = React.forwardRef(({ defaultTitle, defaultContent, titleName, contentName }, ref) => {
  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <TextInputField
          isRequired={true}
          name={titleName}
          label={"Virsraksts*"}
          defaultValue={defaultTitle}
          maxCharLength={100}
        />
      </Grid>
      <Grid item xs={12}>
        <TextEditorTinyMce
          hasImageUpload={true}
          name={contentName}
          defaultValue={defaultContent}
          ref={ref}
        />
      </Grid>
    </Grid>
  );
});
