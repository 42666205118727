import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { PrivateRoute } from "./PrivateRote";
import { HomePrivateRoute } from "./HomePrivateRoute";
import { PrivateRoleRoute } from "./PrivateRoleRoute";
import * as Components from "./components";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "./theme";

import {
  AboutView,
  TermsOfUse,
  UsersDashboardView,
  Moreinformation,
  PersonAdd,
  PersonEditView,
  PersonList,
  FAQ,
  LatvijaLv,
  ResearchAdd,
  ResearchView,
  ResearchDetail,
  ParticipantAdd,
  ClassifierDetail,
  ClassifierList,
  PublicResearchView,
  Role,
  PasswordRefresh,
  PasswordReset,
  PasswordSet,
  UserCreate,
  UserEdit,
  Home,
  UserProfile,
  UserProfileEdit,
  SysNotificationAddView,
  SysNotificationEditView,
  ContentManagement,
  ConsentResearchAdd,
  ConsentBiobankAdd,
  PermissionUpsertView,
  ConsentFillFormView,
  UsefulLinksCms,
  ContactsCms,
  SystemManagerCms,
  TermsOfUseCms,
  TermsOfUseCmsList,
  SysNotificationsView,
  TranslationsView,
  TermsOfUseCmsAdd,
  Contacts,
  FAQCms,
  ReferralList,
  ReferralEdit,
  ReferralAdd,
  ReferralView,
  QuestionView,
  SurveyView,
  ParticipantSurveyView,
  ConsentUpsertView,
  BiobankHomeView,
  UserSubmittedConsentsView,
  AdminSettingsView,
  NotificationsView,
  FAQCmsListView,
  ConsentVersionsView,
  ParticipantResearchTabsView,
  PublicResearchDetailsView,
} from "./pages";

//import ConsentForm from "./pages/research/InformedConsent/ConsentForm";
//currently unsure about naming of those components as business requriements aren't exactly specified
import ConsentFilledBiobank from "./pages/consent/ConsentFilledBiobank";
import ConsentBiobankFilling from "./pages/consent/ConsentBiobankFilling";
import ConsentResearchNurseFill from "./pages/consent/ConsentResearchNurseFill";
import PermissionListView from "./pages/permissions/PermissionListView";
import { userAccessToken } from "./redux/selectors/authSelector";
import { RefreshAccessToken } from "./api/auth/auth";
import "./style/App.scss";
import { MoreInformationCmsAddGroupView } from "./pages/cms/moreInformation/AddGroupView";
import HomeCms from "./pages/cms/home/HomeCms";
import AboutUsCms from "./pages/cms/aboutUs/AboutUsCms";
import { MoreInformationCmsDraggableListView } from "./pages/cms/moreInformation/DraggableListView";
import { MoreInformationAddGroupItemView } from "./pages/cms/moreInformation/AddGroupItemView";
import { MoreInformationEditGroupItemView } from "./pages/cms/moreInformation/EditGroupItemView";
import { MoreInformationCmsEditGroupView } from "./pages/cms/moreInformation/EditGroupView";
import { MoreInformationCms } from "./pages/cms/moreInformation/MoreInformationCms";
import FAQAddGroupView from "./pages/cms/faq/AddGroupView";
import FAQAddGroupItemView from "./pages/cms/faq/AddGroupItemView";
import FAQCmsEditGroupView from "./pages/cms/faq/EditGroupView";
import FAQEditGroupItemView from "./pages/cms/faq/EditGroupItemView";
import { SelectedRowProvider } from './components/SelectedRowProvider';

const restrictedComponentsForTokenRefresh = [
  "/",
  "/latvija-lv",
  "/reset-password",
  "/set-password",
  "/role",
  "/more-information",
  "/contacts",
  "/participants/add",
];

const App = () => {
  const { customTheme } = useTheme();
  //Cannot be used since useNavigate can be used only within Router Context
  //const { token, RefreshAccessToken } = useToken();

  const token = useSelector(userAccessToken);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const allowTokenRefresh = !restrictedComponentsForTokenRefresh.includes(
      window.location.pathname
    );
    if (allowTokenRefresh && token === "") dispatch(RefreshAccessToken());
  }, [dispatch, token]);

  return (
    <SelectedRowProvider>
      <ThemeProvider theme={customTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <Routes>
              <Route element={<Components.Layout />}>
                <Route index element={<HomePrivateRoute><Home /></HomePrivateRoute>} />
                <Route path="/about" element={<AboutView />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/more-information" element={<Moreinformation />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />

                <Route path="/consents/consent-fill" element={<ConsentFillFormView />} />
                <Route path="/consents/:consentId/edit" element={<ConsentUpsertView />} />
                <Route path="/consents/consent-create" element={<ConsentUpsertView />} />
                <Route path="/consents/:consentId/take-over" element={<ConsentUpsertView />} />
                <Route path="/consents/informed-consent-fill" element={<ConsentFillFormView />} />
                <Route path="/consents/user-submitted-consents" element={<UserSubmittedConsentsView />} />

                <Route path="/permissions" element={<PermissionListView />} />
                <Route path="/permissions/create" element={<PermissionUpsertView />} />
                <Route path="/permissions/:id/edit" element={<PermissionUpsertView />} />

                <Route path="/public/researches" element={<PublicResearchView />} />
                <Route path="/public/researches/:researchId/details" element={<PublicResearchDetailsView />} />
                <Route path="/public/researches/:researchId/details/:personId" element={<PublicResearchDetailsView />} />

                <Route path="/participant/research/:researchId/details" element={<ParticipantResearchTabsView />} />
                <Route path="/biobank" element={<BiobankHomeView />} />
                <Route path="/biobank/biobank-consent-create" element={<ConsentUpsertView />} />

                <Route element={<PrivateRoute />}>

                  <Route path="/user-profile/edit" element={<UserProfileEdit />} />
                  <Route path="/user-profile" element={<UserProfile />} />

                  <Route path="/dashboard" element={<UsersDashboardView />} />
                  <Route path="/users/:userId" element={<UserEdit />} /> {/* strange probably only be for admin */}

                  <Route path="/referrals" element={<ReferralList />} />
                  <Route path="/referrals/add" element={<ReferralAdd />} />
                  <Route path="/referrals/:referralId/edit" element={<ReferralEdit />} />
                  <Route path="/referrals/:referralId/view" element={<ReferralView />} />

                  <Route path="/persons" element={<PersonList />} />
                  <Route path="/persons/add" element={<PersonAdd />} />
                  <Route path="/person/:personIds/edit" element={<PersonEditView />} />

                  <Route path="/classifiers/:name/details" element={<ClassifierDetail />} />
                  <Route path="/classifiers" element={<ClassifierList />} />

                  <Route path="/change-role" element={<Role />} />

                  <Route path="/researches" element={<ResearchView />} />
                  <Route path="/researches/:researchId/edit" element={<ResearchAdd />} />
                  <Route path="/researches/:researchId/details" element={<ResearchDetail />} />
                  <Route path="/researches/add" element={<ResearchAdd />} />

                  <Route path="/notifications" element={<NotificationsView />} />
                  <Route path="/notifications/:id/read" element={<NotificationsView />} />
                  <Route path="/notifications/received" element={<NotificationsView />} />
                  <Route path="/notifications/sent" element={<NotificationsView />} />
                  <Route path="/notifications/archived" element={<NotificationsView />} />
                  <Route path="/notifications/create" element={<NotificationsView />} />

                  <Route path="/cms/system-notifications/create" element={<SysNotificationAddView />} />
                  <Route path="/cms/system-notifications/:id/edit" element={<SysNotificationEditView />} />

                  <Route path="/consents/research/:researchId/add" element={<ConsentResearchAdd />} />
                  <Route path="/consents/biobank/add" element={<ConsentBiobankAdd />} />
                  <Route path="/consents/versions" element={<ConsentVersionsView />} />
                  <Route path="/consents/biobank/fill" element={<ConsentBiobankFilling />} />
                  <Route path="/consents/biobank/filled/:id" element={<ConsentFilledBiobank />} />
                  <Route path="/consents/research/nurse" element={<ConsentResearchNurseFill />} />
                  <Route path="/questions" element={< QuestionView />} />
                  <Route path="/surveys" element={< SurveyView />} />
                  <Route path="/surveys/participant-surveys" element={< ParticipantSurveyView />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/user-management/settings" element={<AdminSettingsView />} />
                  <Route path="/user-management/users" element={<AdminSettingsView />} />
                  <Route path="/user-management/rights" element={<AdminSettingsView />} />
                  <Route path="/user-management/groups" element={<AdminSettingsView />} />
                  <Route path="/user-management/group-rights" element={<AdminSettingsView />} />
                  <Route path="/user-management/audit" element={<AdminSettingsView />} />
                  <Route path="/user-management/create-user" element={<UserCreate />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route element={<ContentManagement />}>
                    <Route path="/cms/home" element={<HomeCms />} />
                    <Route path="/cms/about" element={<AboutUsCms />} />

                    <Route element={<MoreInformationCms />}>
                      <Route path="/cms/more-information" element={<MoreInformationCmsDraggableListView />} />
                      <Route path="/cms/more-information/groups/add" element={<MoreInformationCmsAddGroupView />} />
                      <Route path="/cms/more-information/groups/:groupId/item/add" element={<MoreInformationAddGroupItemView />} />
                      <Route path="/cms/more-information/groups/:groupId/edit" element={<MoreInformationCmsEditGroupView />} />
                      <Route path="/cms/more-information/groups/:groupId/item/:itemId/edit" element={<MoreInformationEditGroupItemView />} />
                    </Route>

                    <Route element={<FAQCms />}>
                      <Route path="/cms/faq" element={<FAQCmsListView />} />
                      <Route path="/cms/faq/question-groups/add" element={<FAQAddGroupView />} />
                      <Route path="/cms/faq/question-groups/:groupId/item/add" element={<FAQAddGroupItemView />} />
                      <Route path="/cms/faq/question-groups/:groupId/edit" element={<FAQCmsEditGroupView />} />
                      <Route path="/cms/faq/question-groups/:groupId/item/:itemId/edit" element={<FAQEditGroupItemView />} />
                    </Route>

                    <Route path="/cms/contacts" element={<ContactsCms />} />

                    <Route element={<TermsOfUseCms />}>
                      <Route path="/cms/terms-of-use" element={<TermsOfUseCmsList />} />
                      <Route path="/cms/terms-of-use/add" element={<TermsOfUseCmsAdd />} />
                    </Route>

                    <Route path="/cms/useful-links" element={<UsefulLinksCms />} />
                    <Route path="/cms/system-notifications" element={<SysNotificationsView />} />
                    <Route path="/cms/translations" element={<TranslationsView />} />
                    <Route path="/cms/system-management" element={<SystemManagerCms />} />
                  </Route>
                </Route>
              </Route>
              <Route element={<Components.ProfileLayout />}>

                <Route path="/role" element={<PrivateRoleRoute><Role /></PrivateRoleRoute>} />
                <Route path="/participants/add" element={<ParticipantAdd />} />
                <Route path="/participants/add-using-existing-person/:personId" element={<ParticipantAdd />} />
                <Route path="/refresh-password" element={<PasswordRefresh />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/set-password" element={<PasswordSet />} />
                <Route path="/latvija-lv" element={<LatvijaLv />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </SelectedRowProvider>
  );
};

export default App;
