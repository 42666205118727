const SignatureStatusCodeEnum = Object.freeze({
  Unsigned: "1",
  SignedOneSidedly: "2",
  SignedOnBothSides: "3"
});

const SignatureStatusCodeMapLV = new Map([
  [SignatureStatusCodeEnum.Unsigned, "Neparakstīts"],
  [SignatureStatusCodeEnum.SignedOneSidedly, "Vienpusēji parakstīts"],
  [SignatureStatusCodeEnum.SignedOnBothSides, "Abpusēji parakstīts"]
]);

export {
  SignatureStatusCodeEnum,
  SignatureStatusCodeMapLV
};