import Typography from "@mui/material/Typography";
import React from "react";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Menu from "@mui/icons-material/Menu";
import useRightTranslation from "../../../hooks/useRightTranslation";
import { Link } from "react-router-dom";

//cms item
export const MoreInformationCmsItem = React.forwardRef(({
                                                          isDragging,
                                                          handleItemDialogOpen,
                                                          item,
                                                          dragProps,
                                                          draggableProps
                                                        }, ref) => {
  const { GetRightTranslation } = useRightTranslation();

  const { titleLv, titleEn, titleRu  } = item || {};

  return (
    <>
      <Fade in={true}>
        <Grid
            ref={ref}
            item
            xs={12}
            {...draggableProps}
            {...dragProps}
            bgcolor={isDragging ? "rgb(235,235,235)" : "#fff"}
            boxShadow={"0px 2px 15px rgba(51, 51, 51, 0.15)"}
            my={2}
            p={1}
        >
          <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
          >
            <Grid display={"flex"} gap={".5rem"}>
              <Menu color="info"/>
              <Typography>{GetRightTranslation(titleLv, titleEn, titleRu)}</Typography>
            </Grid>
            <Grid display={"flex"} gap=".5rem">
              <Button
                  startIcon={<Edit/>}
                  color={"info"}
                  component={Link}
                  to={`/cms/more-information/groups/${item.moreInfoGroupsId}/item/${item.id}/edit`}
              >
                Rediģēt
              </Button>
              <Button
                  startIcon={<Delete/>}
                  color="info"
                  onClick={() => handleItemDialogOpen(item.id)}
              >
                Dzēst
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </>
  );
});
