import { useSearchParams } from "react-router-dom";
import { LocalStorageKey } from "../enums";

const useEParaksts = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const processUUID = searchParams.get("process_uuid");

  function hasHappenedRedirectFromESignature() {
    return error?.length > 0 || processUUID?.length > 0;
  };

  function hasError() {
    return error?.length > 0;
  };

  function getErrorValue() {
    return hasError() ? error : null;
  };

  function hasProcessUUID() {
    return processUUID?.length > 0;
  };

  function getProcessUUIDValue() {
    return hasProcessUUID() ? processUUID : null;
  };

  function navigateUserToEparakstsPage(URLNavigateTo) {
    if (!URLNavigateTo?.length)
      return;

    window.location.assign(URLNavigateTo);
  }

  function getReturnURL() {
    const { protocol, hostname, port } = window.location;

    return `${protocol}//${hostname}${port ? `:${port}` : ''}/e-paraksts`;
  }

  function setPathnameToRedirectUser(pathnameToRedirectUser) {
    localStorage.setItem(LocalStorageKey.PathnameToRedirectUserFromESigning, pathnameToRedirectUser);
  }

  function getPathnameToRedirectUser() {
    return localStorage.getItem(LocalStorageKey.PathnameToRedirectUserFromESigning);
  }

  function removePathnameToRedirectUser() {
    localStorage.removeItem(LocalStorageKey.PathnameToRedirectUserFromESigning);
  }

  return {
    hasHappenedRedirectFromESignature,
    hasError,
    getErrorValue,
    hasProcessUUID,
    getProcessUUIDValue,
    navigateUserToEparakstsPage,
    getReturnURL,
    setPathnameToRedirectUser,
    getPathnameToRedirectUser,
    removePathnameToRedirectUser
  }
};

export default useEParaksts;
