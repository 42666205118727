const CanParticipateMessageEnum = Object.freeze({
  CanParticipate: 0,
  NotInList: 1,
  NotInRelatedResearch: 2,
  BiobankConsentNotFilled: 3,
  InformedConsentNotFilled: 4,
  NoParticipationAllowed: 5,
  NoFreeRegistrationCodesWereFound: 6,
  UserAlreadyApplied: 7,
  BioBankConsentNotFound: 8,
  InformedConsentNotFound: 9,
  TaskCreationFailed: 10,
  ExceededMaxNumberOfRefusals: 11,
  NoParticipationAllowedButCanInteractFreely : 12
});

const CanParticipateMessageMapLV = new Map([
  [CanParticipateMessageEnum.CanParticipate, "Jūs varat pieteikties šajā pētījumā"],
  [CanParticipateMessageEnum.NotInList, "Šajā pētījumā Jūs nevarat pieteikties: Jūs neesat uzskaitīts kā dalībnieks."],
  [CanParticipateMessageEnum.NotInRelatedResearch, "Šajā pētījumā Jūs nevarat pieteikties: Jūs neesat saistīts ar saistīto pētījumu."],
  [CanParticipateMessageEnum.BiobankConsentNotFilled, "Šajā pētījumā Jūs nevarat pieteikties: Nav aizpildīta BioBankas piekrišana"],
  [CanParticipateMessageEnum.InformedConsentNotFilled, "Šajā pētījumā Jūs nevarat pieteikties: Nav aizpildīta Informētā piekrišana"],
  [CanParticipateMessageEnum.NoParticipationAllowed, "Šajā pētījumā Jūs nevarat pieteikties: dalībnieku pieteikšanās nav paredzēta"],
  [CanParticipateMessageEnum.NoFreeRegistrationCodesWereFound, "Pašreiz pētījumam nav iespējams pieteikties. Lūdzu, sazinieties ar administratoru!"],
  [CanParticipateMessageEnum.UserAlreadyApplied, "Jūs esat pieteicies šim pētījumam."],
  [CanParticipateMessageEnum.ExceededMaxNumberOfRefusals, "Atkārtoti pieteikties pētījumā nevar!"],
  [CanParticipateMessageEnum.NoParticipationAllowedButCanInteractFreely, "Nevar pieteikties pētījumam, bet var brīvi ar pētījumu strādāt"]

]);

export {
  CanParticipateMessageEnum,
  CanParticipateMessageMapLV
};
