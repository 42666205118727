import React, { Suspense, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Outlet, Navigate } from "react-router-dom";
import { useAccessToken } from "../hooks";
import { userService } from "../services";
import { configureStore } from "../redux/store";
import {
  setAuthenticated,
  setAccessRights,
  setTokenLifeTime,
  setSingleUserRole
} from "../redux/actions/auth";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { UserRoleNameFromCodeMap, SessionStorageKey } from "../enums/index";


const PrivateRoute = ({ allowedRoleName = null }) => {
  const { isAuthenticated, isAccessTokenExpired, accessToken } = useAccessToken();
  const userRoleName = sessionStorage.getItem(SessionStorageKey.Role);
  const currentPersonRights = useSelector((state) => state.Auth.accessRights);

  useEffect(() => {
    if (isAccessTokenExpired() || sessionStorage.getItem(SessionStorageKey.UserName)?.length)
      return;

    const abortController = new AbortController();
    async function logInUser() {
      try {
        const decodedToken = jwtDecode(accessToken);
        const currentPersonInformation = await userService.getPersonInformationById(decodedToken.PersonId, abortController.signal);
        sessionStorage.setItem(SessionStorageKey.IsRoleSelecting, false);
        sessionStorage.setItem(SessionStorageKey.UserName, currentPersonInformation.userName);
        configureStore.dispatch(setAccessRights(currentPersonRights));

        sessionStorage.setItem(SessionStorageKey.Time, decodedToken?.exp);
        sessionStorage.setItem(SessionStorageKey.Role, UserRoleNameFromCodeMap.get(decodedToken?.RoleCode));
        configureStore.dispatch(setTokenLifeTime(decodedToken?.exp));
        configureStore.dispatch(setAuthenticated(true));
        configureStore.dispatch(setSingleUserRole(
          {
            code: decodedToken?.RoleCode,
            name: UserRoleNameFromCodeMap.get(decodedToken?.RoleCode)
          }
        ));
      } catch (error) {
        if (error.name !== "AbortError")
          console.error(error?.message);
      }
    }

    logInUser();

    return () => {
      abortController.abort();
    }
  }, []);


  let isAuthenticatedUser = false;
  if (!isAccessTokenExpired() && accessToken?.length)
    isAuthenticatedUser = true;

  sessionStorage.setItem(SessionStorageKey.IsAuthenticated, isAuthenticatedUser)

  if (!isAuthenticated()) {
    return <Suspense fallback={<CircularProgress />}><Navigate to="/" replace /></Suspense>;
  }

  if (isAuthenticated() && (allowedRoleName?.length && userRoleName !== allowedRoleName)) {
    return <Suspense fallback={<CircularProgress />}><Navigate to="/dashboard" replace /></Suspense>;
  }

  return (
    <>
      <Suspense fallback={<CircularProgress />}>
        <Outlet />
      </Suspense>
    </>
  );
}

export default PrivateRoute;
