import { lazy } from "react";
//Route-based code-splitting
export const AboutView = lazy(() => import("./about/aboutView"));
export const TermsOfUse = lazy(() => import("./termsOfUse/Termsofuse"));
export const UsersDashboardView = lazy(() => import("./dashboard/UsersDashboardView"));
export const Moreinformation = lazy(() => import("./moreinfo/moreinfo"));
export const FAQ = lazy(() => import("./faq/FAQ"));
export const LatvijaLv = lazy(() => import("./latvijaLv/LatvijaLv"));
export const PersonAdd = lazy(() => import("./persons/personAdd"));
export const PersonEditView = lazy(() => import("./persons/PersonEditView"));
export const PersonList = lazy(() => import("./persons/personList"));
export const Home = lazy(() => import("./home/homeView"));
export const ResearchView = lazy(() => import("./research/adminView/researchView/ResearchView"));
export const ResearchAdd = lazy(() => import("./research/form/AddResearch"));
export const ResearchDetail = lazy(() => import("./research/adminView/ResearchTabs"));
export const ParticipantAdd = lazy(() => import("./participant/participantAdd"));
export const ClassifierDetail = lazy(() => import("./classifier/Classifier"));
export const ClassifierList = lazy(() => import("./classifier/ClassifierList"));
export const PublicResearchView = lazy(() => import("./research/participantView/PublicResearchView"));
export const Contacts = lazy(() => import("./contacts/Contacts"));
export const Role = lazy(() => import("./role/role"));
export const PasswordRefresh = lazy(() => import("./password_reset/passwordRefresh"));
export const PasswordReset = lazy(() => import("./password_reset/passwordReset"));
export const PasswordSet = lazy(() => import("./password_reset/passwordSet"));
export const UserCreate = lazy(() => import("./adminPanel/UserCreate"));
export const UserEdit = lazy(() => import("./adminPanel/UserEdit"));
export const UserProfile = lazy(() => import("./user/userProfile"));
export const UserProfileEdit = lazy(() => import("./user/userProfileEdit"));
export const SysNotificationAddView = lazy(() => import("./notifications/SysNotificationAddView"));
export const SysNotificationEditView = lazy(() => import("./notifications/SysNotificationEditView"));
export const UsefulLinksCms = lazy(() => import("./cms/usefulLinks/usefulLinksTabs"));
export const ContentManagementLayoutView = lazy(() => import("./cms/ContentManagementTabPanel"));
export const FAQCms = lazy(() => import("./cms/faq/FAQCms"));
export const ContactsCms = lazy(() => import("./cms/contacts/ContactsCms"));
export const SystemManagerCms = lazy(() => import("./cms/systemManager/SystemManager"));
export const SysNotificationsView = lazy(() => import("./cms/SysNotificationsView"));
export const TermsOfUseCms = lazy(() => import("./cms/termsOfUse/TermsOfUse"));
export const TermsOfUseCmsList = lazy(() => import("./cms/termsOfUse/TermsOfUseCmsList"));
export const TermsOfUseCmsAdd = lazy(() => import("./cms/termsOfUse/TermsOfUseCmsAdd"));
export const TranslationsView = lazy(() => import("./cms/TranslationsView"));
export const BiobankCms = lazy(() => import("./cms/biobank/BiobankCms"));
export const ConsentResearchAdd = lazy(() => import("./consent/ConsentResearchAdd"));
export const ConsentBiobankAdd = lazy(() => import("./consent/ConsentBiobankAdd"));
export const PermissionUpsertView = lazy(() => import("./permissions/PermissionUpsertView"));
export const ReferralList = lazy(() => import("./referral/ReferralList"));
export const ReferralEdit = lazy(() => import("./referral/ReferralEdit"));
export const ReferralAdd = lazy(() => import("./referral/ReferralAdd"));
export const ReferralView = lazy(() => import("./referral/ReferralView"));
export const ReferralDetails = lazy(() => import("./referral/ReferralDetails"))
export const ConsentFillFormView = lazy(() => import("./research/adminView/new-informed-consent-view/ConsentFillFormView"));
export const QuestionView = lazy(() => import("./questions/QuestionView"));
export const SurveyView = lazy(() => import("./survey/SurveyView"));
export const ResearchSurveyView = lazy(() => import("./research/ResearchSurveyView"));
export const SurveyParticipant = lazy(() => import("./research/participantView/surveyView/SurveyParticipant"));
export const ParticipantSurveyView = lazy(() => import("./survey/ParticipantSurveyView"));
export const ConsentUpsertView = lazy(() => import("./research/adminView/new-informed-consent-view/ConsentUpsertView"));
export const SurveyAdminUpsertView = lazy(() => import("./research/adminView/surveyView/SurveyAdminUpsertView"));
export const BiobankHomeView = lazy(() => import("./biobank/BiobankHomeView"));
export const SurveyQuestionUpsertView = lazy(() => import("./research/adminView/surveyView/SurveyQuestionUpsertView"));
export const UserSubmittedConsentsView = lazy(() => import("./research/adminView/new-informed-consent-view/UserSubmittedConsentsView"));
export const ResearchResultsView = lazy(() => import("./research/ResearchResultsView"));
export const AdminSettingsView = lazy(() => import("./adminPanel/AdminSettingsTabPanelView"));
export const NotificationsView = lazy(() => import("./notifications/NotificationTabsView"));
export const FAQCmsListView = lazy(() => import("./cms/faq/DraggableListView"));
export const ConsentVersionsView = lazy(() => import("./research/adminView/informedConsentOld/ConsentVersionsView"));
export const ParticipantResearchTabsView = lazy(() => import("./research/participantView/ResearchTabsView"));
export const PublicResearchDetailsView = lazy(() => import("./research/publicView/PublicResearchDetailsView"));
export const FAQCmsGroupAdd = lazy(() => import("./cms/faq/AddGroupView"));
export const FAQCmsGroupEdit = lazy(() => import("./cms/faq/EditGroupView"));
export const FAQCmsGroupItemAdd = lazy(() => import("./cms/faq/AddGroupItemView"));
export const FAQCmsGroupItemEdit = lazy(() => import("./cms/faq/EditGroupItemView"));
export const NotFoundView = lazy(() => import("./NotFoundView"));
export const ErrorPageView = lazy(() => import("./ErrorPageView"));

export const PermissionListView = lazy(() => import("./permissions/PermissionListView"));
export const ConsentFilledBiobankView = lazy(() => import("./consent/ConsentFilledBiobank"));
export const ConsentBiobankFillingView = lazy(() => import("./consent/ConsentResearchNurseFill"));
export const ConsentResearchNurseFillView = lazy(() => import("./permissions/PermissionListView"));

export const FAQEditGroupItemView = lazy(() => import("./cms/faq/EditGroupItemView"));
export const FAQCmsEditGroupView = lazy(() => import("./cms/faq/EditGroupView"));
export const FAQAddGroupItemView = lazy(() => import("./cms/faq/AddGroupItemView"));
export const FAQAddGroupView = lazy(() => import("./cms/faq/AddGroupView"));

export const MoreInformationCmsAddGroupView = lazy(() => import("./cms/moreInformation/AddGroupView"));
export const HomeCmsView = lazy(() => import("./cms/home/HomeCms"));
export const AboutUsCmsView = lazy(() => import("./cms/aboutUs/AboutUsCms"));

export const MoreInformationCmsDraggableListView = lazy(() => import("./cms/moreInformation/MoreInformationCmsDraggableListView"));
export const MoreInformationAddGroupItemView = lazy(() => import("./cms/moreInformation/MoreInformationAddGroupItemView"));
export const MoreInformationEditGroupItemView = lazy(() => import("./cms/moreInformation/EditGroupItemView"));
export const MoreInformationCmsEditGroupView = lazy(() => import("./cms/moreInformation/EditGroupView"));
export const MoreInformationCmsLayoutView = lazy(() => import("./cms/moreInformation/MoreInformationCms"));

export const SignedConsentView = lazy(() => import("./consent/signed-consents/SignedConsentView"))

export const ESigningView = lazy(() => import("./e-signing/ESigningView"));
