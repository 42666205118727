import { Grid, Dialog, DialogTitle,DialogContentText, DialogContent, DialogActions, Button, Pagination    } from "@mui/material";

import { OutlinedBox } from "../index";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../MUI_components";
import ConsentCard from "./ConsentCard";
import consentService from "../../services/consent-service";
import CONSTANTS from "../../constants";

const ActionTypeWithConsent = Object.freeze({
  Delete: 1,
  Publish: 2,
  Edit: 3,
  Fill: 4
});

const ConsentCardsList = ({ consentType, researchId = null }) => {
  const navigate = useNavigate();
  const pageSize = CONSTANTS.PAGINATION.PAGE_SIZE;

  const [consents, setConsents] = useState([]);
  const [consentListPage, setConsentListPage] = useState(CONSTANTS.PAGINATION.PAGE + 1);
  const [consentListTotalPageCount, setConsentListTotalPageCount] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false);
  const [consentIdToManupulateWith, setConsentIdToManupulateWith] = useState(null);
  const [actionTypeWithConsent, setActionTypeWithConsent] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const [promptTitle, setPromptTitle] = useState("");
  const [promptContentText, setPromptContentText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");

  useEffect(()=>{
    const abortController = new AbortController();
    const fetchData = async() => {
      await fetchConsents({
        type: consentType,
        pageNumber: consentListPage - 1,
        pageSize: pageSize,
        researchId: researchId
      }, abortController.signal)
    }
    fetchData();
    return ()=>{
      abortController.abort();
    }
  },[consentListPage, consentType, pageSize, researchId])

  async function fetchConsents(queryParams, signal) {
    try {
      const data = await consentService.getConsents(queryParams, signal);
      setConsents(data.items);
      setConsentListTotalPageCount(data.totalPages);
    } catch (error) {
      if (error.name !== "AbortError")
          console.error(error?.mesage);
    }
  }

  async function forceUpdateList() {
      await fetchConsents({
        type: consentType,
        pageNumber: consentListPage - 1,
        pageSize: pageSize,
        researchId: researchId
      }, null);
  }

  function  handlePaginationChange(event, value) {
    setConsentListPage(value);
  }

  function handleConsentDelete(consentToDelete){
    setConsentIdToManupulateWith(consentToDelete.id);
    setActionTypeWithConsent(ActionTypeWithConsent.Delete);
    setShowPrompt(true);
    setPromptTitle("Brīdinājums");
    setPromptContentText("Vai tiešām vēlaties izdzēst šo informēto piekrišanu?");
    setErrorMessage("Piekrišana netika dzēsta. Meģīniet velreiz.");
    setSuccesMessage("Veiksmīgi tika izdzēsta informētā piekrišana!");
  }

  function handlePromtCancelClick() {
    setShowPrompt(false);
    setConsentIdToManupulateWith(null);
  }

  async function handlePromptContinueClick() {
    try {
      switch (actionTypeWithConsent) {
        case ActionTypeWithConsent.Delete:
            await consentService.deleteConsent(consentIdToManupulateWith);
          break;
        case ActionTypeWithConsent.Publish:
            await consentService.publishConsent(consentIdToManupulateWith);
          break;
        default:
          return;
      }
        await forceUpdateList();
        setIsSuccess(true);
    } catch (error) {
      console.error(error?.message);
      setIsSuccess(false);
    } finally {
      setShowToast(true);
      setShowPrompt(false);
      setConsentIdToManupulateWith(null);
    }
  }

  function handleConsentPublish(consentToPublish) {
    setShowPrompt(true);
    setPromptTitle("Brīdinājums");
    setConsentIdToManupulateWith(consentToPublish.id);
    setActionTypeWithConsent(ActionTypeWithConsent.Publish);
    setPromptContentText("Vai tiešām vēlaties publicēt šo informēto piekrišanu?");
    setErrorMessage("Piekrišana netika publicēta. Meģīniet velreiz.");
    setSuccesMessage("Veiksmīgi tika publicēta informētā  piekrišana!");
  }

  function handleConsentFill(consentToFill) {
    navigate("/consents/informed-consent-fill",{
      state: {
        consent: consentToFill
      }
    });
  }

  function handleConsentEditClick(consentToEdit) {
      navigate(`/consents/${consentToEdit.id}/edit`, {
        state: {
          consentType: consentType,
          researchId: researchId
        }
      });
  }
    return (
    <>
        {(consents.length ? true : false) &&<OutlinedBox>
          <Grid container direction="column" gap={2}>
            <Grid container item direction="column" gap={2}>
              {consents && consents.map(consent => 
                <ConsentCard
                  key={consent.id}
                  consent={consent}
                  handleConsentDelete={handleConsentDelete}
                  handleConsentFill={handleConsentFill}
                  handleConsentPublish={handleConsentPublish}
                  handleConsentEdit={handleConsentEditClick}
                />
              )}
            </Grid>
            <Grid item>
              <Pagination
                count={consentListTotalPageCount}
                page={consentListPage}
                onChange={handlePaginationChange}
              />
            </Grid>
          </Grid>
        </OutlinedBox>}
        <Dialog
            open={showPrompt}
            onClose={() => setShowPrompt(false)}
          >
            <DialogTitle>{promptTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {promptContentText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    onClick={handlePromptContinueClick}
                    color="primary"
                    variant="contained"
                    sx={{height: "40px"}}
                    fullWidth
                  >
                  Turpināt
                </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{height: "40px"}}
                    fullWidth
                    onClick={handlePromtCancelClick}
                    autoFocus
                  >
                    Atcelt
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
        </Dialog>
        {/*Toast*/}
        <Toast
          showToast={showToast}
          handleClose={()=>setShowToast(false)}
          message={isSuccess ? succesMessage : errorMessage}
          failure={!isSuccess}
        />
    </> );
}
export default ConsentCardsList;